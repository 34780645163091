import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Protected } from '~/components/Protected';
import { EditLocation } from './routes/EditLocation';

export const LocationsRoutes: FC = () => {
  return (
    <Protected scope="admin:locations:update">
      <Routes>
        <Route path=":uid" element={<EditLocation />} />
      </Routes>
    </Protected>
  );
};
