import React, { useState } from 'react';
import { message, Select, Timeline, Typography } from 'antd';
import { EditCard } from '~/components/EditCard';
import { Form, FormItem } from '~/components/BaseForm';
import { useMutation } from '~/dataSource/useMutation';
import { getLocalDate, LocalDate } from '~/components/LocalDate';
import {
  TagOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';

const toDate = (v: string) => new Date(v.endsWith('Z') ? v : v + 'Z');

type UpdateExamStatusInput = {
  status: string;
};

type Props = {
  exam: string;
  onSuccess?: () => void;
  statuses?: {
    status: string;
    created_at: string;
  }[];
  deletedStatuses?: {
    status: string;
    created_at: string;
    deleted_at: string;
  }[];
};

export const ExamStatuses: React.FC<Props> = ({
  exam,
  statuses,
  deletedStatuses,
  onSuccess,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [form] = Form.useForm<UpdateExamStatusInput>();

  const { run: updateExamStatus, loading: runLoading } = useMutation<
    unknown,
    UpdateExamStatusInput
  >(`/exams/${exam}/status`, {
    method: 'put',
  });

  function onFinish(values: UpdateExamStatusInput) {
    updateExamStatus(values)
      .then(() => {
        onSuccess?.();
        message.success('Exam Status updated with success!');
      })
      .catch((err) => {
        console.error(err);
        message.error('Ops, Something went wrong!');
      })
      .finally(() => setEditing(false));
  }

  const onEdit = (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <FormItem
        name="status"
        label="New Status"
        initialValue={statuses?.[statuses?.length || 0]?.status}
      >
        <Select
          options={statuses?.map((option) => ({
            value: option.status,
            label: `${option.status} @ ${getLocalDate(option.created_at)}`,
          }))}
        />
      </FormItem>
    </Form>
  );

  const items = React.useMemo(() => {
    const items: {
      time: Date;
      label: string;
      deleted: boolean;
      sign: boolean;
    }[] = [];

    for (const value of statuses || []) {
      items.push({
        time: toDate(value.created_at),
        label: value.status,
        deleted: false,
        sign: value.status == 'report_signed',
      });
    }

    for (const value of deletedStatuses || []) {
      items.push({
        time: toDate(value.created_at),
        label: value.status,
        deleted: true,
        sign: false,
      });
    }

    return items.sort((a, b) => a.time.getTime() - b.time.getTime());
  }, [statuses, deletedStatuses]);

  return (
    <EditCard
      scope="admin:exams:status:update"
      isEditing={isEditing}
      onEditingChange={setEditing}
      editChildren={onEdit}
      actionProps={{
        loading: runLoading,
        onSave: () => form.submit(),
      }}
      cardProps={{
        title: 'Status Timeline',
        bodyStyle: { paddingTop: 32 },
      }}
    >
      <Timeline
        rootClassName="qt-timeline"
        mode="right"
        items={items.map((it) => ({
          children: <LocalDate datetime={it.time.toISOString()} />,
          label: (
            <Typography.Text delete={it.deleted} disabled={it.deleted}>
              {it.label}
            </Typography.Text>
          ),
          color: it.deleted ? 'gray' : it.sign ? 'green' : '#069',
          dot: it.deleted ? (
            <MinusCircleOutlined />
          ) : it.sign ? (
            <CheckCircleOutlined />
          ) : (
            <TagOutlined />
          ),
        }))}
      />
    </EditCard>
  );
};
