import React from 'react';
import { Card, Table } from 'antd';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryTheme } from 'victory';
import { useQuery } from '~/dataSource/useQuery';
import { getLocalDate } from '~/components/LocalDate';

const GET_EXAM_WEEKLY = `
  SELECT g.date, coalesce(c.count, 0)::int AS count
  FROM generate_series(
    date_trunc('week', now())::date - 8,
    date_trunc('week', now())::date + 5,
    '1 day'
  ) AS g(date)
  LEFT JOIN (
    SELECT
      COUNT(*)::int
    , status
    , DATE_TRUNC('day', created_at) as date
    FROM exams_status
    WHERE status IN ($[status])
    AND created_at >= date_trunc('week', now())::date - 8
    GROUP BY 2, 3
  ) as c ON c.date = g.date
  ORDER BY 1 ASC
`;

type Entity = {
  date: string;
  count: number;
  status: string;
};

const PREVIOUS_COLOR = '#a8071a';
const CURRENT_COLOR = '#389e0d';

type Props = {
  title: string;
  status: string;
};

export const Exams: React.FC<Props> = ({ title, status }) => {
  const { data } = useQuery<Entity[]>(GET_EXAM_WEEKLY, {
    variables: { status },
  });

  const previous = React.useMemo(
    () => data?.slice(0, 7).map(({ count: y }, x) => ({ x, y })) || [],
    [data]
  );

  const current = React.useMemo(
    () => data?.slice(7).map(({ count: y }, x) => ({ x, y })) || [],
    [data]
  );

  return (
    <Card title={title}>
      <VictoryChart theme={VictoryTheme.material} width={720} domainPadding={{ y: 5 }}>
        <VictoryAxis
          tickValues={[0, 1, 2, 3, 4, 5, 6]}
          tickFormat={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
        />
        <VictoryAxis dependentAxis standalone={false} />

        <VictoryLine
          name="Previous week"
          data={previous}
          style={{ data: { stroke: PREVIOUS_COLOR } }}
        />
        <VictoryLine
          name="Current week"
          data={current}
          style={{ data: { stroke: CURRENT_COLOR } }}
        />
      </VictoryChart>

      <Table
        size="small"
        rowKey="date"
        pagination={false}
        columns={[
          {
            dataIndex: 'color',
            title: '',
            render: (background) => (
              <div style={{ width: 10, height: 10, borderRadius: 4, background }} />
            ),
          },
          { dataIndex: 'date', title: 'Date' },
          { dataIndex: ['week', 0], title: 'Sun' },
          { dataIndex: ['week', 1], title: 'Mon' },
          { dataIndex: ['week', 2], title: 'Tue' },
          { dataIndex: ['week', 3], title: 'Wed' },
          { dataIndex: ['week', 4], title: 'Thu' },
          { dataIndex: ['week', 5], title: 'Fri' },
          { dataIndex: ['week', 6], title: 'Sat' },
          { dataIndex: 'total', title: 'Total' },
        ]}
        dataSource={[
          {
            date: getLocalDate(data?.[0].date, 'DD/MM/YYYY') + ' Previous week',
            color: PREVIOUS_COLOR,
            week: previous.map((e) => e.y),
            total: previous.map((e) => e.y).reduce((a, b) => a + b, 0),
          },
          {
            date: getLocalDate(data?.[7].date, 'DD/MM/YYYY') + ' Current week',
            color: CURRENT_COLOR,
            week: current.map((e) => e.y),
            total: current.map((e) => e.y).reduce((a, b) => a + b, 0),
          },
        ]}
      />
    </Card>
  );
};
