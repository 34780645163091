import { useCallback, useEffect, useState } from 'react';
import { parse } from './sql';
import { request } from './client';

type TState<T> = {
  data?: T;
  loading: boolean;
};

type UseQueryConfig<TValues> = {
  skip?: boolean;
  variables?: TValues;
};

type UseQueryExt<T> = { refetch: (config?: UseQueryConfig<T>) => void };

export function useQuery<
  TData,
  TVars extends Record<string, any> = Record<string, any>
>(query: string, config?: UseQueryConfig<TVars>): TState<TData> & UseQueryExt<TVars> {
  const [state, setState] = useState<TState<TData>>({ loading: false });
  const [variables, setVariables] = useState<TVars | undefined>(config?.variables);
  const [version, setVersion] = useState('initial');
  const [skip, setSkip] = useState(config?.skip ?? false);

  useEffect(() => {
    if (skip) {
      return;
    }

    setState((state) => ({ ...state, loading: true }));
    request<{ rows: TData }>('/query', {
      method: 'POST',
      body: JSON.stringify(parse(query, variables)),
    }).then((data) => setState({ loading: false, data: data.rows }));
  }, [variables, skip, version]);

  const refetch = useCallback((config?: UseQueryConfig<TVars>) => {
    setVariables(config?.variables ?? variables);
    setSkip(config?.skip ?? skip);
    setVersion(new Date().toISOString());
  }, []);

  return {
    ...state,
    refetch,
  };
}
