import React from 'react';
import { message, Modal, Form, Switch, Alert, Input, Row, Col } from 'antd';
import { useMutation } from '~/dataSource/useMutation';

type Props = {
  exam?: {
    uid: string;
    status: string;
    demographics: {
      pacemaker: boolean;
    };
  };
  patient?: { name: string; document: string };
  onComplete: () => void;
};

type Input = {
  pacemaker: boolean;
  processExam: boolean;
};

export const UpdateExamPacemakerForm: React.FC<Props> = ({
  exam,
  patient,
  onComplete,
}) => {
  const canProcessExam = [
    'annotations_created',
    'statistics_created',
    'ready_to_report',
  ].includes(exam?.status as string);
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [document, setDocument] = React.useState('');
  const [$form] = Form.useForm();

  const { run: updateExamPacemaker } = useMutation<void, Input>(
    `exams/[uid]/pacemaker`,
    { method: 'patch' }
  );

  function onCancel() {
    setLoading(false);
    setVisible(false);
    setDocument('');
    $form.setFieldValue('pacemaker', exam?.demographics.pacemaker);
    $form.setFieldValue('processExam', false);
    $form.setFieldValue('document', '');
  }

  async function onSubmit(evt?: React.FormEvent) {
    evt?.preventDefault();
    setLoading(true);
    updateExamPacemaker(
      {
        pacemaker: $form.getFieldValue('pacemaker'),
        processExam: $form.getFieldValue('processExam'),
      },
      { uid: exam?.uid }
    )
      .then(() => {
        message.success('Exam pacemaker updated with success!');
        if (exam) {
          exam.demographics.pacemaker = $form.getFieldValue('pacemaker');
        }
        onComplete();
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong!');
      })
      .finally(() => onCancel());
  }

  return (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        Update Exam Pacemaker
      </a>
      <Modal
        title="Update Exam Pacemaker"
        okText="Confirm"
        open={visible}
        onOk={onSubmit}
        onCancel={onCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{
          disabled: patient?.document !== document,
          loading,
        }}
      >
        {canProcessExam && (
          <>
            <Alert
              showIcon
              type="error"
              description={
                <>
                  You are about to change patient <strong>{patient?.name}</strong> exam
                  pacemaker data.{' '}
                  <strong>
                    Be aware that, in case you opt to process the exam, this action will
                    remove all already processed data and run it again
                  </strong>
                  .
                </>
              }
            />
            <br />
          </>
        )}

        <p>
          If you are sure you want to update the exam pacemaker data, fill the form, and
          click confirm.
        </p>
        <p>
          Name: <strong>{patient?.name}</strong>
          <br />
          Document: <strong>{patient?.document}</strong>
        </p>

        <Form form={$form} layout="vertical">
          <Row>
            <Col span={12}>
              <Form.Item
                label="Pacemaker"
                name="pacemaker"
                initialValue={exam?.demographics.pacemaker}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>

            {canProcessExam && (
              <Col span={12}>
                <Form.Item
                  label="Process exam"
                  name="processExam"
                  initialValue={false}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Form.Item name="document" rules={[{ required: true }]}>
            <Input
              type="text"
              placeholder="Document"
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e) => setDocument(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
