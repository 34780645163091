import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Table, TablePaginationConfig } from 'antd';
import { LocalDate } from '~/components/LocalDate';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';
import { CreateLocationModal } from './CreateLocationModal';

const GET_LOCATIONS = `
  SELECT
    l.uid,
    l.name,
    l.address,
    l.city,
    l.state,
    l.postal_code,
    l.created_at,
    l.updated_at
  FROM locations l
  WHERE l.organization = $[organization] $[[WHERE]]
  ORDER BY l.name ASC, l.created_at DESC
`;

interface GetLocationsVars {
  organization: string;
  _where?: string;
}

interface Entity {
  uid: string;
  name: string;
  document: string;
  document_type: string;
}

type Props = {
  organization: string;
  loading?: boolean;
};

const RESULTS_PER_PAGE = 10;

export const LocationsTab: FC<Props> = (props) => {
  const [visible, setVisibile] = useState(false);

  const { rows, totalCount, loading, paginate, refetch } = useQueryPaginated<
    Entity,
    GetLocationsVars
  >(GET_LOCATIONS, {
    skip: !props.organization,
    variables: {
      organization: props.organization,
    },
    pagination: {
      perPage: RESULTS_PER_PAGE,
    },
  });

  function onChange(pagination: TablePaginationConfig) {
    paginate({ page: pagination.current, perPage: pagination.pageSize });
  }

  function onSearch(value: string) {
    const _value = value.trim().split(' ').join('%');

    const _where = _value ? `AND name ILIKE '%${_value}%'` : '';

    paginate({ page: 1 });
    refetch({ _where, organization: props.organization });
  }

  return (
    <>
      <Input.Search
        enterButton
        allowClear
        placeholder="Filter By Name"
        onSearch={onSearch}
      />
      <Table<Entity>
        rowKey="uid"
        loading={loading}
        onChange={onChange}
        footer={() => <Button onClick={() => setVisibile(true)}>Add Location</Button>}
        dataSource={rows}
        pagination={{
          total: totalCount,
          pageSize: RESULTS_PER_PAGE,
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Address',
            dataIndex: 'address',
          },
          {
            title: 'City',
            dataIndex: 'city',
          },
          {
            title: 'State',
            dataIndex: 'state',
          },
          {
            title: 'Postal Code',
            dataIndex: 'postal_code',
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            render: (it) => <LocalDate datetime={it} />,
          },
          {
            title: 'Updated At',
            dataIndex: 'updated_at',
            render: (it) => <LocalDate datetime={it} />,
          },
          {
            title: 'Actions',
            dataIndex: 'uid',
            render: (uid) => {
              return <Link to={`/locations/${uid}`}>Edit</Link>;
            },
          },
        ]}
      />
      <CreateLocationModal
        visible={visible}
        organization={props.organization}
        onCancel={(created) => {
          setVisibile(false);
          created && refetch();
        }}
      />
    </>
  );
};
