import { FC } from 'react';
import { Checkbox, Input, Select, Spin } from 'antd';
import { useLocationSearch } from '~/hooks/useLocationSearchHook';
import { BaseForm, BaseFormProps, FormItem } from '~/components/BaseForm';

export const DockingStationForm: FC<BaseFormProps> = (props) => {
  const { data, loading, search } = useLocationSearch();
  return (
    <BaseForm {...props}>
      <FormItem
        label="Location"
        name="location"
        rules={[{ required: true, message: 'please input docking station location' }]}
      >
        <Select
          showSearch
          labelInValue
          showArrow={false}
          filterOption={false}
          onSearch={(value) => search(value, !value)}
          notFoundContent={loading ? <Spin size="small" /> : null}
          options={data?.map(({ uid: value, name: label }) => ({ value, label }))}
        />
      </FormItem>

      <FormItem
        label="Serial number"
        name="serial_number"
        rules={[
          { required: true, message: 'please input docking station serial number' },
        ]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="Model"
        name="model"
        rules={[{ required: true, message: 'please input docking station model' }]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="Software version"
        name="software_version"
        rules={[
          { required: true, message: 'please input docking station software version' },
        ]}
      >
        <Input readOnly />
      </FormItem>

      <FormItem label="Runtime Logging Level" name="logLevel">
        <Select>
          <Select.Option value={0}>Minimal</Select.Option>
          <Select.Option value={1}>Info</Select.Option>
          <Select.Option value={2}>Debug</Select.Option>
        </Select>
      </FormItem>

      <FormItem
        name="debugging"
        valuePropName="checked"
        wrapperCol={{ offset: 6, span: 16 }}
      >
        <Checkbox>Enable Bluetooth Debugging</Checkbox>
      </FormItem>
    </BaseForm>
  );
};
