import React from 'react';
import { Input, message, Modal, Form, Alert } from 'antd';
import { useMutation } from '~/dataSource/useMutation';

type Props = {
  exam: string;
  patient?: { name: string; document: string };
};

export const DeleteExamReportForm: React.FC<Props> = ({ exam, patient }) => {
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [document, setDocument] = React.useState('');
  const [$form] = Form.useForm();

  const { run: deleteExamReport } = useMutation<void, void>(`exams/${exam}/report`, {
    method: 'delete',
  });

  function onCancel() {
    setLoading(false);
    setVisible(false);
  }

  async function onSubmit(evt?: React.FormEvent) {
    evt?.preventDefault();
    setLoading(true);
    deleteExamReport()
      .then(() => {
        message.success('Exam report deleted with success!');
        $form.setFieldValue('document', '');
        setDocument('');
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong!');
      })
      .finally(() => onCancel());
  }

  return (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        Delete Exam Report
      </a>
      <Modal
        title="Delete Exam Report"
        okText="Confirm"
        open={visible}
        onOk={onSubmit}
        onCancel={onCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{
          disabled: patient?.document !== document,
          loading,
        }}
      >
        <Alert
          showIcon
          type="error"
          description={
            <>
              You are about to delete patient <strong>{patient?.name}</strong> exam
              report. This action is <strong>irreversible</strong>.
            </>
          }
        />
        <br />
        <p>
          If you are sure you want to delete this exam report, type the patient's
          document below and click confirm.
        </p>
        <p>
          Name: <strong>{patient?.name}</strong>
          <br />
          Document: <strong>{patient?.document}</strong>
        </p>

        <Form form={$form} layout="vertical">
          <Form.Item name="document" rules={[{ required: true }]}>
            <Input
              type="text"
              placeholder="Document"
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e) => setDocument(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
