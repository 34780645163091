import { useCallback, useEffect, useState } from 'react';
import { parseURL, request } from './client';

type TState<T> = {
  data?: T;
  loading: boolean;
};

type UseFetchConfig<TVariables> = {
  skip?: boolean;
  method?: string;
  onSuccess?: (data: any) => void;
  onError?: (err: Error) => void;
  variables: TVariables;
};

type RefetchConfig<TVariables> = Exclude<
  UseFetchConfig<TVariables>,
  'onSuccess' | 'onError'
>;

type UseQueryExt<T> = { refetch: (config?: RefetchConfig<T>) => void };

export function useFetch<
  TData,
  TVars extends Record<string, any> = Record<string, any>
>(path: string, config?: UseFetchConfig<TVars>): TState<TData> & UseQueryExt<TVars> {
  const [state, setState] = useState<TState<TData>>({ loading: false });
  const [variables, setVariables] = useState<TVars | undefined>(config?.variables);
  const [version, setVersion] = useState('initial');
  const [skip, setSkip] = useState(config?.skip ?? false);

  useEffect(() => {
    if (skip) {
      return;
    }

    setState((state) => ({ ...state, loading: true }));

    request<TData>(parseURL(path, variables), {
      method: config?.method || 'GET',
    })
      .then((data) => {
        setState({ loading: false, data });
        return data;
      })
      .then((data) => config?.onSuccess?.(data))
      .catch((err) => {
        setState((state) => ({ ...state, loading: false }));
        if (config?.onError) {
          return config.onError(err);
        }
        throw err;
      });
  }, [JSON.stringify(variables), skip, version]);

  const refetch = useCallback((config?: UseFetchConfig<TVars>) => {
    setVariables(config?.variables ?? variables);
    setSkip(config?.skip ?? skip);
    setVersion(new Date().toISOString());
  }, []);

  return {
    refetch,
    ...state,
  };
}
