import { Switch } from 'antd';
import { FormItem } from '~/components/BaseForm';
import { PreferencesForm } from '../abstract/PreferencesForm';

type Props = {
  organization: string;
};

export const ContactPreferencesTab: React.FC<Props> = ({ organization }) => (
  <PreferencesForm organization={organization} segment="contact">
    <FormItem
      label="Display Contact Segment?"
      name="showContact"
      initialValue={true}
      valuePropName="checked"
    >
      <Switch />
    </FormItem>
  </PreferencesForm>
);
