import { Card, Descriptions } from 'antd';
import { useQuery } from '~/dataSource/useQuery';
import { useQueryEntity } from '~/dataSource/useQueryEntity';

const GET_OWNERS = `
  WITH owners AS (
    SELECT
      jsonb_object_keys(owners) AS "key",
      (owners->>jsonb_object_keys(owners))::uuid AS value
    FROM zoho_tasks zt
    WHERE exam = $[uid]
  )
  SELECT o."key", ov.physician FROM owners o
  LEFT JOIN operators_view ov ON ov.uid = o.value
`;
const GET_ZOHO_DETAILS = `
  WITH owners AS (
    SELECT
      jsonb_each_text(owners)
    FROM zoho_tasks
    WHERE exam = $[uid]
  )
  SELECT
    ROW_TO_JSON(zt.*) task
  , ROW_TO_JSON(zp.*) project
  FROM zoho_tasks zt
  JOIN zoho_projects zp ON zp.organization = zt.organization
  WHERE zt.exam = $[uid]
`;

type OwnerEntity = {
  key: string;
  uid: string;
  physician: {
    uid: string;
    name: string;
    document: string;
    document_type: string;
    created_at: string;
    updated_at: string;
  };
};

type Entity = {
  task: {
    exam: string;
    task_id: string;
    created_at: string;
    updated_at: string;
    metadata: {
      priority: string;
      status: string;
      end_date: string;
      start_date: string;
    };
    owners: {
      report_signer: string | null;
      report_reviewer: string | null;
      quorelead_reviewer: string | null;
      clustering_reviewer: string | null;
    };
  };
  project: {
    organization: string;
    project_id: string;
    tasklist_id: string;
    ignore: boolean;
  };
};

type Props = {
  uid: string;
};

export function ZohoDetails({ uid }: Props) {
  const owners = useQuery<OwnerEntity[]>(GET_OWNERS, { variables: { uid } });
  const zoho = useQueryEntity<Entity, Props>(GET_ZOHO_DETAILS, { variables: { uid } });

  return (
    <>
      <Card title="Owners" type="inner" loading={owners.loading}>
        <Descriptions size="middle" column={3}>
          <Descriptions.Item label="Reviewer" span={1}>
            {
              owners.data?.find((it) => it.key === 'quorelead_reviewer')?.physician
                ?.name
            }
          </Descriptions.Item>
          <Descriptions.Item label="Releaser" span={1}>
            {owners.data?.find((it) => it.key === 'report_reviewer')?.physician?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Signer" span={1}>
            {owners.data?.find((it) => it.key === 'report_signer')?.physician?.name}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <br />

      <Card title="Metadata" type="inner" loading={zoho.loading}>
        <pre>
          <code>{JSON.stringify(zoho.data, null, 2)}</code>
        </pre>
      </Card>
    </>
  );
}
