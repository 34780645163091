import { useState, useEffect, useCallback } from 'react';
import { request } from './client';
import { parse } from './sql';

type Pagination = {
  page: number;
  perPage: number;
};

const GLOBAL_DEFAULT_PARAMS: Pagination = {
  page: 1,
  perPage: 100,
};

function fromPageToQuery(
  params?: Partial<Pagination>,
  defaults = GLOBAL_DEFAULT_PARAMS
) {
  const limit = params?.perPage || defaults.perPage;
  const page = params?.page || defaults.page;
  const offset = Math.max(page - 1, 0) * limit;

  return { limit, offset };
}

type QueryConfig<T> = {
  skip?: boolean;
  variables?: T;
  pagination?: Partial<Pagination>;
};

type PaginationState = {
  pagination: Pagination;
};

type Result<T> = {
  rows: T[];
  totalCount: number;
  loading: boolean;
};

type UseQueryFns<T> = {
  refetch: (vars?: T) => void;
  paginate: (params: Partial<Pagination>) => void;
};

export function useQueryPaginated<
  TEntity,
  TVars extends Record<string, any> = Record<string, any>
>(
  query: string,
  config?: QueryConfig<TVars>
): UseQueryFns<TVars> & PaginationState & Result<TEntity> {
  const [pagination, setPagination] = useState<Pagination>(
    Object.assign({}, GLOBAL_DEFAULT_PARAMS, config?.pagination)
  );
  const [variables, setVariables] = useState<TVars | undefined>(config?.variables);
  const [state, setState] = useState<Result<TEntity>>({
    loading: false,
    totalCount: 0,
    rows: [],
  });

  const [version, setVersion] = useState('initial');

  const refetch = useCallback((newVariables?: TVars) => {
    setVariables(newVariables || variables);
    setVersion(new Date().toISOString());
  }, []);

  const paginate = useCallback((newPagination: Partial<Pagination>) => {
    setPagination((state) => Object.assign({}, state, newPagination));
  }, []);

  useEffect(() => {
    if (config?.skip) {
      return;
    }

    setState((state) => ({ ...state, loading: true }));

    const PAGINATED_QUERY = query + ' LIMIT $[limit] OFFSET $[offset]';
    const COUNT_QUERY = query
      .replace(/[\n\r]/g, '')
      .replace(/SELECT.*FROM/, 'SELECT COUNT(*)::int4 FROM')
      .replace(/ORDER BY .*/, '');

    request<[{ rows: TEntity[] }, { rows: { count: number }[] }]>('/query', {
      method: 'POST',
      body: JSON.stringify([
        parse(PAGINATED_QUERY, {
          ...variables,
          ...fromPageToQuery(pagination),
        }),
        parse(COUNT_QUERY, variables),
      ]),
    }).then(([entities, counter]) => {
      if ((entities as any).error || (counter as any).error) {
        console.error(entities, counter);
      }

      setState((state) => ({
        ...state,
        loading: false,
        rows: entities.rows || [],
        totalCount: counter.rows?.[0]?.count || 0,
      }));
    });
  }, [variables, pagination, config?.skip, version]);

  return {
    ...state,
    pagination,
    refetch,
    paginate,
  };
}
