import { FC } from 'react';
import { Col, Divider, Row } from 'antd';
import { Exams } from './widgets/Exams';
import { Counters } from './widgets/Counters';
import { Year } from './widgets/Year';

export const Homepage: FC = () => {
  return (
    <>
      <Counters />
      <Divider />
      <Row gutter={18}>
        <Col span={12}>
          <Exams title="Exams Ready to Report Weekly" status="ready_to_report" />
        </Col>

        <Col span={12}>
          <Exams title="Exams Created Weekly" status="created" />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Year title="Report Signed YTD" status="report_signed" />
        </Col>
      </Row>
    </>
  );
};
