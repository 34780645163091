import { FunctionComponent } from 'react';
import { Form, Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';

interface Props {
  onSubmit(username: string, password: string): void;
}

export const SignIn: FunctionComponent<Props> = ({ onSubmit }) => {
  const onFinish = (values: any) => {
    onSubmit(values.username, values.password);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: '13rem',
            height: '3rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            borderRadius: '0.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <GoogleOutlined style={{ fontSize: '1.1rem' }} />
          <span style={{ fontSize: '1rem' }}>
            Login com o <span style={{ fontWeight: 'bold' }}>Google</span>
          </span>
        </Button>
      </Form.Item>
    </Form>
  );
};
