import { FC } from 'react';
import { Statistic, Row, Col, Card } from 'antd';
import { useQuery } from '~/dataSource/useQuery';

const GET_PATIENTS_STATISTICS = `
  SELECT COUNT(*), sex::text FROM patients GROUP BY sex
  UNION
  SELECT COUNT(*), 'total_patients' sex FROM patients
`;

type GetPatientsStatisticsResponse = { count: number; sex: string }[];

export const PatientsStatistics: FC = () => {
  const { data, loading } = useQuery<GetPatientsStatisticsResponse>(
    GET_PATIENTS_STATISTICS
  );

  return (
    <Row gutter={[16, { xs: 4, sm: 8, md: 16 }]}>
      <Col sm={8} xxl={4}>
        <Card>
          <Statistic
            loading={loading}
            title="Total Patients"
            value={data?.find((e) => e.sex === 'total_patients')?.count}
          />
        </Card>
      </Col>

      <Col sm={8} xxl={4}>
        <Card>
          <Statistic
            title="Male"
            loading={loading}
            value={data?.find((c) => c.sex === 'male')?.count}
          />
        </Card>
      </Col>

      <Col sm={8} xxl={4}>
        <Card>
          <Statistic
            title="Female"
            loading={loading}
            value={data?.find((c) => c.sex === 'female')?.count}
          />
        </Card>
      </Col>
    </Row>
  );
};
