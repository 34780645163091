import dayjs from 'dayjs';
import React from 'react';

export const getLocalDate = (datetime?: string, format = 'DD/MM/YYYY HH:mm:ss') => {
  const isDateOnly = datetime?.length === 10;
  return datetime
    ? dayjs(datetime.endsWith('Z') || isDateOnly ? datetime : datetime + 'Z').format(
        format
      )
    : '';
};

export const LocalDate: React.FC<{ datetime?: string; format?: string }> = ({
  datetime: date,
  format,
}) => <time title={date}>{getLocalDate(date, format)}</time>;
