import { FC } from 'react';
import { Statistic, Row, Col, Card } from 'antd';
import { useQuery } from '~/dataSource/useQuery';

const POSITIVE_COLOR = { backgroundColor: '#d6eadf' };
const NEGATIVE_COLOR = { backgroundColor: '#eac4d5' };

const GET_EXAMS_STATISTICS = `
  SELECT COUNT(*), status::text FROM exams GROUP BY status
  UNION
  SELECT COUNT(*), 'total_exams' status FROM exams
`;

type GetExamsStatisticsResponse = { count: number; status: string }[];

export const ExamsStatistics: FC = () => {
  const { data, loading } = useQuery<GetExamsStatisticsResponse>(GET_EXAMS_STATISTICS);

  return (
    <Row gutter={[8, { xs: 4, sm: 8 }]}>
      <Col sm={4}>
        <Card>
          <Statistic
            title="Install"
            loading={loading}
            value={data?.find((c) => c.status === 'created')?.count}
          />
        </Card>
      </Col>

      <Col sm={4}>
        <Card>
          <Statistic
            title="Monitor"
            loading={loading}
            value={data?.find((c) => c.status === 'receiving_packets')?.count}
          />
        </Card>
      </Col>

      <Col sm={4}>
        <Card>
          <Statistic
            title="A.I."
            loading={loading}
            value={data?.find((c) => c.status === 'creating_annotations')?.count}
          />
        </Card>
      </Col>

      <Col sm={4}>
        <Card>
          <Statistic
            title="Review"
            loading={loading}
            value={data?.find((c) => c.status === 'statistics_created')?.count}
          />
        </Card>
      </Col>

      <Col sm={4}>
        <Card>
          <Statistic
            title="Release"
            loading={loading}
            value={data?.find((c) => c.status === 'ready_to_report')?.count}
          />
        </Card>
      </Col>

      <Col sm={4}>
        <Card>
          <Statistic
            title="Sign"
            loading={loading}
            value={data?.find((c) => c.status === 'ready_to_sign')?.count}
          />
        </Card>
      </Col>

      <Col sm={8}>
        <Card style={NEGATIVE_COLOR}>
          <Statistic
            title="Archived"
            loading={loading}
            value={data?.find((c) => c.status === 'archived')?.count}
          />
        </Card>
      </Col>

      <Col sm={8}>
        <Card style={POSITIVE_COLOR}>
          <Statistic
            title="Report Finished"
            loading={loading}
            value={data?.find((c) => c.status === 'report_signed')?.count}
          />
        </Card>
      </Col>

      <Col sm={8}>
        <Card style={POSITIVE_COLOR}>
          <Statistic
            loading={loading}
            title="Total Exams"
            value={data?.find((e) => e.status === 'total_exams')?.count}
          />
        </Card>
      </Col>
    </Row>
  );
};
