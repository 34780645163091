import { Card, Divider, Form, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '~/dataSource/useFetch';
import { PageHeader } from '~/components/PageHeader';
import { PolicyEntity } from '../entities/Policy';
import { PolicyForm } from '../components/PolicyForm';
import { useMutation } from '~/dataSource/useMutation';

export function NewPolicy() {
  const navigate = useNavigate();

  const [form] = Form.useForm<PolicyEntity>();
  const availablePermissions = useFetch<string[]>('/permissions');

  const createPolicy = useMutation<any, PolicyEntity>('policies/new');

  function onFinish(input: PolicyEntity) {
    createPolicy
      .run(input)
      .then(() => {
        message.success('Policy created with success');
        navigate('/policies');
      })
      .catch((err) => {
        message.error('Ops, Something went wrong!');
        console.error(err);
      });
  }

  return (
    <>
      <PageHeader title="New Policy" />

      <Divider />

      <Card>
        <PolicyForm
          form={form}
          onFinish={onFinish}
          loading={createPolicy.loading}
          availablePermissions={availablePermissions.data || []}
        />
      </Card>
    </>
  );
}
