type ParseResult = {
  text: string;
  values: any[];
};

export function parse(query: string, params: Record<string, any> = {}): ParseResult {
  const values: any = [];

  let text = query.replace(/[\n\r]/g, '').trim();

  const vars = text.match(/(\$\[[\w]+\])/g);
  const specialVars = text.match(/(\$\[\[[\w]+\]\])/g);

  vars?.forEach((key, idx) => {
    text = text.replace(key, `$${idx + 1}`);
    values[idx] = params[key.replace(/[^\w]/g, '')];
  });

  specialVars?.forEach((key) => {
    const name = '_' + key.replace(/[^\w]/g, '').toLowerCase();
    text = text.replace(key, params[name] || '');
  });

  return { text, values };
}
