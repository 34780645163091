import React from 'react';
import { Input, message, Modal, Select, Form } from 'antd';
import { useMutation } from '~/dataSource/useMutation';

interface IArchiveExamInput {
  reason?: string | null;
}

type Props = {
  uid: string;
  onComplete: () => void;
};

const REASONS = [
  'Demo Exam',
  'Test Exam',
  'Discard - Poor Signal Quality',
  'Discard - Exam with excessive artifacts and lead-off',
  'Discard - Exam with missing packets',
  'Device - Blinking Blue after exam start',
  'Device - Blinking Green after exam start',
  'Device - Blinking Blue and Green after exam start',
  'Device - Stop working',
  'Wrong Settings - Duration',
  'Wrong Settings - Transmission mode',
  'App - Fail during exam start',
  'Other (Please Describe)',
];

export const ArchiveExamForm: React.FC<Props> = ({ uid, onComplete }) => {
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [$form] = Form.useForm();

  const { run: archiveExam } = useMutation<void, IArchiveExamInput>(
    `exams/${uid}/archive`
  );

  async function onSubmit(evt?: React.FormEvent) {
    evt?.preventDefault();
    setLoading(true);
    archiveExam({ reason: $form.getFieldValue('reason') })
      .then(() => {
        message.success('Exam was archived with success!');
        onComplete();
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong!');
      })
      .finally(() => onCancel());
  }

  function onCancel() {
    setLoading(false);
    setVisible(false);
  }

  return (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        Archive Exam
      </a>
      <Modal
        title="Archive Exam"
        open={visible}
        okText="Confirm"
        onOk={onSubmit}
        onCancel={onCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ loading }}
      >
        <Form form={$form} layout="vertical">
          <Form.Item name="base" label="Reasons">
            <Select
              onChange={(value) => {
                const newValue = value.includes('Other') ? null : value;
                $form.setFieldValue('reason', newValue);
              }}
              options={REASONS.map((value) => ({ value, label: value }))}
            />
          </Form.Item>
          <Form.Item name="reason" label="Description" rules={[{ required: true }]}>
            <Input type="text" placeholder="Reason" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
