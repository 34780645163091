import React from 'react';
import { Layout } from 'antd';
import { Sidebar } from './components/Sidebar';

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout.Content style={{ padding: 18 }}>{children}</Layout.Content>
    </Layout>
  );
};
