import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Protected } from '~/components/Protected';
import { ViewExam } from './routes/ViewExam';
import { ListExams } from './routes/ListExams';

export const ExamsRoutes: FC = () => {
  return (
    <Protected scope="admin:exams:list">
      <Routes>
        <Route path="/" element={<ListExams />} />
        <Route path=":uid" element={<ViewExam />} />
      </Routes>
    </Protected>
  );
};
