import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';
import { LocalDate } from '~/components/LocalDate';
import { EditPermissions } from './EditPermissions';

const GET_OPERATORS = `
  SELECT
    uid
  , created_at
  , deleted_at
  , organization
  , permissions
  FROM operators_view ov
  WHERE ov.physician->>'uid' = $[physician]
  ORDER BY deleted_at NULLS FIRST
`;

interface GetOperatorsVars {
  physician: string;
}

interface Entity {
  uid: string;
  created_at: string;
  deleted_at: string;
  permissions: string[];
  organization: {
    uid: string;
    name: string;
  };
}

type Props = {
  physician: string;
};

export const OperatorList: React.FC<Props> = ({ physician }) => {
  const [editOperator, setEditOperator] = React.useState('');

  const { rows, totalCount, loading } = useQueryPaginated<Entity, GetOperatorsVars>(
    GET_OPERATORS,
    {
      skip: !physician,
      variables: {
        physician,
      },
      pagination: {
        perPage: 100,
      },
    }
  );

  return (
    <>
      <EditPermissions uid={editOperator} onClose={() => setEditOperator('')} />
      <Table<Entity>
        loading={loading}
        dataSource={rows}
        rowKey="uid"
        rowClassName={(record) => (record.deleted_at ? 'qt-deleted-row' : '')}
        footer={() => <span>Total: {totalCount}</span>}
        columns={[
          {
            title: 'Organization',
            dataIndex: 'organization',
            render: (value: Entity['organization']) => {
              return <Link to={`/organizations/${value.uid}`}>{value.name}</Link>;
            },
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            render: (it) => <LocalDate datetime={it} />,
          },
          {
            title: 'Deleted At',
            dataIndex: 'deleted_at',
            render: (it) => <LocalDate datetime={it} />,
          },
          {
            title: 'UID',
            dataIndex: 'uid',
          },
          {
            width: 90,
            title: 'Actions',
            render: (row) => {
              if (row.deleted_at) {
                return null;
              }
              return (
                <Button
                  type="link"
                  target="_blank"
                  rel="noopener noparent"
                  href={`${process.env.REACT_APP_PLATFORM_URL}/profile?switch=${row.uid}`}
                >
                  View as
                </Button>
              );
            },
          },
          {
            width: 90,
            title: 'Permissions',
            render: (row) => {
              if (row.deleted_at) {
                return null;
              }

              return (
                <Button type="link" onClick={() => setEditOperator(row.uid)}>
                  Edit
                </Button>
              );
            },
          },
        ]}
      />
    </>
  );
};
