import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input } from 'antd';
import { LocalDate } from '~/components/LocalDate';
import { PageHeader } from '~/components/PageHeader';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';

const GET_QUOREONES = `
  SELECT
    d.uid,
    d.serial_number,
    d.firmware_version,
    d.mac_address,
    o.uid "organization_uid",
    o.name "organization_name",
    d.status,
    d.created_at,
    d.updated_at
  FROM devices d
  LEFT JOIN locations l ON l.uid = d.location
  LEFT JOIN organizations o ON o.uid = l.organization
  $[[WHERE]]
  ORDER BY d.created_at DESC
`;

interface Entity {
  uid: string;
  model_number: string;
  serial_number: string;
  firmware_version: string;
  mac_address: string;
  organization_uid: string;
  organization_name: string;
  status: 'active' | 'inactive' | 'maintenance';
  created_at: Date;
  updated_at: Date;
}

const RESULTS_PER_PAGE = 20;

export const ListQuoreones: FC = () => {
  const { rows, totalCount, loading, paginate, refetch } = useQueryPaginated<Entity>(
    GET_QUOREONES,
    {
      pagination: {
        perPage: RESULTS_PER_PAGE,
      },
    }
  );

  function onSearch(value: string) {
    const _where = value
      ? `WHERE d.serial_number ILIKE '%${value}%' OR d.mac_address ILIKE '%${value}%'`
      : '';
    refetch({ _where });
  }

  return (
    <>
      <PageHeader title="QuoreOne List" />

      <div style={{ padding: '0 12px' }}>
        <Input.Search
          enterButton
          allowClear
          placeholder="Filter by SN or Mac Address"
          onSearch={onSearch}
        />
        <br />
        <br />
        <Table<Entity>
          rowKey="uid"
          loading={loading}
          dataSource={rows}
          onChange={(pagination) => {
            paginate({ page: pagination.current, perPage: pagination.pageSize });
          }}
          pagination={{
            total: totalCount,
            showSizeChanger: true,
            defaultPageSize: RESULTS_PER_PAGE,
          }}
          columns={[
            {
              width: 70,
              title: 'UID',
              dataIndex: 'uid',
              render: (uid: string) => <code>{uid.split('-')[0]}</code>,
            },
            {
              title: 'Serial Number',
              dataIndex: 'serial_number',
            },
            { title: 'Firmware Version', dataIndex: 'firmware_version' },
            { title: 'MAC Address', dataIndex: 'mac_address' },
            {
              title: 'Organization',
              dataIndex: 'organization_uid',
              render: (organization, row) => (
                <Link to={`/organizations/${organization}`}>
                  {row.organization_name}
                </Link>
              ),
            },
            { title: 'Status', dataIndex: 'status' },
            {
              title: 'Created At',
              dataIndex: 'created_at',
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              title: 'Updated At',
              dataIndex: 'updated_at',
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              title: 'Action',
              dataIndex: 'uid',
              render: (uid) => {
                return <Link to={uid}>Details</Link>;
              },
            },
          ]}
        />
      </div>
    </>
  );
};
