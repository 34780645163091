import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { getCurrent } from '~/models/user';

type Props = {
  scope: string;
  children: React.ReactElement;
};

export function Protected({ scope, children }: Props): React.ReactElement | null {
  const user = getCurrent();

  if (user.can(scope)) {
    return children;
  }

  return (
    <Result
      title="403"
      status="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  );
}
