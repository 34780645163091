import { useEffect, useRef, useState } from 'react';

export function useElementWidth() {
  const [width, setWidth] = useState<number>();
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWidth = () =>
      container.current && setWidth(container.current.getBoundingClientRect().width);

    window.addEventListener('resize', updateWidth);

    updateWidth();

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [container]);

  return {
    width,
    container,
  };
}
