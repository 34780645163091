import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Form, message, Select, Space } from 'antd';
import { ServiceType, ServiceTypeOptions } from '~/components/ServiceType';
import { useMutation } from '~/dataSource/useMutation';

interface UpdateServiceTypePayload {
  serviceType: string;
}

const ExamServiceForm: React.FC<{
  initialValues: {
    exam?: string;
    serviceType?: string;
  };
  onCancel: () => void;
  onSuccess: () => void;
}> = ({ onCancel, onSuccess, initialValues }) => {
  const { run: updateExamServiceType, loading } = useMutation<
    void,
    UpdateServiceTypePayload
  >(`/exams/${initialValues.exam}/service`, {
    method: 'patch',
  });

  async function onFinish(payload: UpdateServiceTypePayload) {
    try {
      await updateExamServiceType(payload);
      message.success('Service type updated with success!');
      onSuccess();
    } catch (err) {
      console.error(err);
      message.error('Something went wrong!');
      onCancel();
    }
  }

  return (
    <Form onFinish={onFinish} initialValues={initialValues}>
      <Space>
        <Form.Item noStyle name="serviceType">
          <Select
            style={{ minWidth: 100 }}
            disabled={loading}
            size="small"
            defaultValue="essential"
            filterOption={false}
            options={Array.from(ServiceTypeOptions, ([key, option]) => ({
              label: option.label,
              value: key,
            }))}
          />
        </Form.Item>

        <Button disabled={loading} onClick={onCancel} size="small">
          Cancel
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          size="small"
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
      </Space>
    </Form>
  );
};

type Props = {
  onUpdate: () => void;
  loading: boolean;
  exam?: {
    uid: string;
    status?: string;
    service_type?: string;
  };
};

export const ExamService: React.FC<Props> = ({ exam, loading, onUpdate }) => {
  const [isEditing, setEditing] = useState(false);

  const canEdit = !['report_signed', 'archived'].includes(exam?.status || '') && exam;

  if (!canEdit || loading) {
    return <ServiceType>{exam?.service_type}</ServiceType>;
  }

  if (isEditing) {
    return (
      <ExamServiceForm
        initialValues={{
          exam: exam.uid,
          serviceType: exam.service_type,
        }}
        onCancel={() => setEditing(false)}
        onSuccess={() => {
          setEditing(false);
          onUpdate();
        }}
      />
    );
  }

  return (
    <>
      <ServiceType>{exam?.service_type}</ServiceType>
      <a href="#" onClick={() => setEditing(true)}>
        <EditOutlined />
      </a>
    </>
  );
};
