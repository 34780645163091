import { FC } from 'react';
import { DatePicker, Input, Radio } from 'antd';
import { BaseForm, BaseFormProps, FormItem } from '~/components/BaseForm';

export const PatientForm: FC<BaseFormProps> = (props) => {
  return (
    <BaseForm {...props}>
      <FormItem
        label="Name"
        name="name"
        rules={[{ required: true, message: 'please input patient name' }]}
      >
        <Input />
      </FormItem>
      <FormItem
        label="Document"
        name="document"
        rules={[{ required: true, message: 'please input patient document' }]}
      >
        <Input />
      </FormItem>

      <FormItem label="Birthdate" name="birthdate" rules={[{ required: true }]}>
        <DatePicker allowClear={false} />
      </FormItem>

      <FormItem label="Sex" name="sex">
        <Radio.Group>
          <Radio.Button value="male">Male</Radio.Button>
          <Radio.Button value="female">Female</Radio.Button>
        </Radio.Group>
      </FormItem>

      <FormItem label="Email" name="email">
        <Input allowClear />
      </FormItem>
    </BaseForm>
  );
};
