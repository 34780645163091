import React from 'react';
import { Form as AntForm, FormProps, Button } from 'antd';

export type BaseFormProps = Pick<
  FormProps,
  'onFinish' | 'onFinishFailed' | 'initialValues' | 'form' | 'disabled' | 'layout'
> & {
  loading?: boolean;
  children?: React.ReactNode;
};

export const Form = AntForm;

export const FormItem = Form.Item;

export const BaseForm: React.FC<BaseFormProps> = ({ loading, children, ...props }) => {
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 8 }} {...props}>
      {children}
      <FormItem wrapperCol={{ offset: 6, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
          Save
        </Button>
      </FormItem>
    </Form>
  );
};
