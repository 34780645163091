import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Divider, Form, message } from 'antd';
import { PageHeader } from '~/components/PageHeader';
import { useMutation } from '~/dataSource/useMutation';
import { OrganizationForm } from '../components/OrganizationForm';

interface CreateOrganizationInput {
  name: string;
  status: string;
  parent?: string;
}

interface CreateOrganizationResponse {
  uid: string;
}

export const CreateOrganization: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<CreateOrganizationInput>();

  const { run: createOrganization, loading } = useMutation<
    CreateOrganizationResponse,
    CreateOrganizationInput
  >('organizations');

  function onFinish(input: CreateOrganizationInput) {
    createOrganization(input)
      .then((response) => {
        message.success('Organization created with success');
        navigate(`/organizations/${response.uid}`);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }

  return (
    <Card>
      <PageHeader title="Create Organization" onBack={() => navigate(-1)} />

      <Divider />

      <OrganizationForm form={form} onFinish={onFinish} loading={loading} />
    </Card>
  );
};
