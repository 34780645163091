import { useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { DownOutlined } from '@ant-design/icons';
import { generateExamLink } from '~/helpers/datadog';
import { ArchiveExamForm } from './ArchiveExamForm';
import { RestoreExamDataForm } from './RestoreExamDataForm';
import { ResetExamUploadDataForm } from './ResetExamUploadDataForm';
import { DeleteExamReportForm } from './DeleteExamReportForm';
import { FinishExamUploadForm } from './FinishExamUploadForm';
import { useViewExamContext } from '../contexts/ViewExamContext';
import { UpdateExamPacemakerForm } from './UpdateExamPacemakerForm';
import { getCurrent } from '~/models/user';

type Props = {
  uid: string;
};

export function ExamActions({ uid }: Props) {
  const user = getCurrent();
  const { data, loading, refetch } = useViewExamContext();

  const task = data?.task;

  const items = useMemo(() => {
    const items: ItemType[] = [
      {
        key: 'datadogLink',
        label: (
          <a
            key="openDatadogLink"
            href={generateExamLink(uid)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open in Datadog
          </a>
        ),
      },
    ];

    if (task?.project_id && task.tasklist_id && task.task_id) {
      items.push({
        key: 'zohoProjectsLink',
        label: (
          <a
            key="openZohoProjectsLink"
            href={`https://projects.zoho.com/portal/quoretech#taskdetail/${task.project_id}/${task.tasklist_id}/${task.task_id}`}
            target="_blank"
            rel="noopener noparent"
          >
            Open in Zoho Projects
          </a>
        ),
      });
    }

    if (task?.ticket_id) {
      items.push({
        key: 'zohoDeskLink',
        label: (
          <a
            key="openZohoDeskLink"
            href={`https://support.quore.tech/agent/quoretech/all/tickets/details/${task?.ticket_id}`}
            target="_blank"
            rel="noopener noparent"
          >
            Open in Zoho Desk
          </a>
        ),
      });
    }

    if (
      !['archived', 'report_signed'].includes(data?.exam.status || '') &&
      user.can('admin:exams:demographics:pacemaker:update')
    ) {
      items.push({
        key: 'updateExamPacemakerForm',
        label: (
          <UpdateExamPacemakerForm
            exam={data?.exam}
            patient={data?.patient}
            onComplete={() => refetch()}
          />
        ),
      });
    }

    if (data?.exam.status !== 'archived' && user.can('admin:exams:archive')) {
      items.push({
        danger: true,
        key: 'archiveExam',
        label: <ArchiveExamForm uid={uid} onComplete={() => refetch()} />,
      });
    }

    if (user.can('admin:exams:report:delete') && !data?.exam.metadata.purged_at) {
      items.push({
        danger: true,
        key: 'deleteExamReportForm',

        label: <DeleteExamReportForm exam={uid} patient={data?.patient} />,
      });
    }

    if (user.can('admin:exams:holter:restore')) {
      items.push({
        danger: true,
        key: 'restoreExamDataForm',
        label: <RestoreExamDataForm exam={uid} patient={data?.patient} />,
      });
    }

    if (user.can('admin:exams:upload:reset')) {
      items.push({
        danger: true,
        key: 'resetUploadDataForm',
        label: <ResetExamUploadDataForm exam={uid} patient={data?.patient} />,
      });
    }

    if (
      user.can('admin:exams:upload:finish') &&
      data?.exam.status.endsWith('_packets')
    ) {
      items.push({
        danger: true,
        key: 'finishExamUpload',
        label: (
          <FinishExamUploadForm
            exam={uid}
            patient={data?.patient}
            onComplete={() => refetch()}
          />
        ),
      });
    }

    return items;
  }, [task, data, user]);

  return (
    <Dropdown.Button
      loading={loading}
      disabled={loading}
      trigger={['click']}
      menu={{ items }}
      icon={<DownOutlined />}
      buttonsRender={(btn) => [
        <Button
          target="_blank"
          rel="noopener noparent"
          key="openPlatformLink"
          href={`${process.env.REACT_APP_PLATFORM_URL}/exam/${uid}`}
        >
          Open in Platform
        </Button>,
        btn[1],
      ]}
    ></Dropdown.Button>
  );
}
