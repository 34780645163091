import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Devices } from './routes/Devices';
import { BatteryCheck } from './routes/BatteryCheck';
import { QuoreOnesRoutes } from './QuoreOnesRoutes';
import { DockingStationsRoutes } from './DockingStationsRoutes';

export const DevicesRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Devices />} />
      <Route path="/battery" element={<BatteryCheck />} />
      <Route path="/quoreones/*" element={<QuoreOnesRoutes />} />
      <Route path="/dockings/*" element={<DockingStationsRoutes />} />
    </Routes>
  );
};
