import { Input, Transfer } from 'antd';
import { BaseForm, BaseFormProps, FormItem } from '~/components/BaseForm';

export function PolicyForm(
  props: BaseFormProps & {
    availablePermissions: string[];
  }
) {
  return (
    <BaseForm {...props}>
      <FormItem label="Name" name="name" rules={[{ required: true }]}>
        <Input type="text" disabled={props.loading} />
      </FormItem>

      <FormItem label="Description" name="description" rules={[{ required: true }]}>
        <Input.TextArea disabled={props.loading} />
      </FormItem>

      <FormItem
        label="Permissions"
        name="permissions"
        valuePropName="targetKeys"
        rules={[{ required: true }]}
      >
        <Transfer
          showSearch
          titles={['Available', 'Allowed']}
          disabled={props.loading}
          render={(item) => item.name}
          dataSource={props.availablePermissions.sort().map((code) => ({
            key: code,
            name: code,
          }))}
          listStyle={{ width: '100%', height: 400 }}
        />
      </FormItem>
    </BaseForm>
  );
}
