import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ExamStatus } from '~/components/ExamStatus';
import { LocalDate } from '~/components/LocalDate';
import { PageHeader } from '~/components/PageHeader';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';
import { Duration, durationHuman } from '~/helpers/duration';

const YESTERDAY_DEVICES = `
  SELECT
    e.uid
  , e.status
  , d.uid "deviceUid"
  , d.serial_number "serialNumber"
  , e.options->>'duration' "durationMin"
  , bl.created_at "created_at"
  , bl.information->>'duration_s' "durationSec"
  , bl.information->>'initial_level' "initialLevel"
  , bl.information->>'final_level' "finalLevel"
  , bl.information->>'drainage_per_hour' "drainagePerHour"
  FROM metrics.battery_levels bl
  JOIN exams e on e.uid = bl.exam
  JOIN devices d ON d.uid = e.device
  $[[WHERE]]
  ORDER BY bl.created_at DESC
`;

interface Entity {
  uid: string;
  deviceUid: string;
  status: string;
  serialNumber: string;
  durationMin: string;
  date: string;
  durationSec: string;
  initialLevel: string;
  finalLevel: string;
  drainagePerHour: string;
}

export function BatteryCheck() {
  const { loading, rows, totalCount, pagination, paginate } = useQueryPaginated<Entity>(
    YESTERDAY_DEVICES,
    {
      pagination: {
        perPage: 20,
      },
      variables: {
        _where: `WHERE (e.options->>'duration')::int >= 1080`,
      },
    }
  );

  return (
    <>
      <PageHeader title="Battery Check" />
      <Table<Entity>
        rowKey="uid"
        loading={loading}
        dataSource={rows}
        onChange={(pagination) => {
          paginate({ page: pagination.current, perPage: pagination.pageSize });
        }}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: pagination.page,
          pageSize: pagination.perPage,
        }}
        columns={[
          {
            width: 70,
            title: 'Exam',
            dataIndex: 'uid',
            render: (uid: string) => (
              <Link to={`/exams/${uid}`}>
                <code>{uid.split('-')[0]}</code>
              </Link>
            ),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (status: string) => <ExamStatus status={status} />,
          },
          {
            title: 'Exam Duration',
            dataIndex: 'durationMin',
            render: (duration) => durationHuman(duration),
          },
          {
            title: 'Serial Number',
            dataIndex: 'serialNumber',
            render: (uid: string, row) => (
              <Link to={`/devices/quoreones/${row.deviceUid}`}>
                <code>{uid}</code>
              </Link>
            ),
          },
          {
            title: 'Initial Level',
            dataIndex: 'initialLevel',
            render: (v) => Number(Number(v).toFixed(1)) + '%',
          },
          {
            title: 'Final Level',
            dataIndex: 'finalLevel',
            render: (v) => Number(Number(v).toFixed(1)) + '%',
          },
          {
            title: 'Estimate',
            dataIndex: 'drainagePerHour',
            render: (v) => {
              const value = Number(v);

              if (Number(value) === 0) {
                return 'Infinity';
              }

              const type =
                value >= 0.6
                  ? 'danger'
                  : value >= 0.55
                  ? 'warning'
                  : value <= 0.001
                  ? 'secondary'
                  : 'success';

              return (
                <Typography.Text type={type}>
                  {Duration.fromHours(100 / Number(value)).toString(false)}
                </Typography.Text>
              );
            },
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            render: (it) => <LocalDate datetime={it} />,
          },
        ]}
      />
    </>
  );
}
