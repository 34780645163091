import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Table, TablePaginationConfig } from 'antd';
import { durationHuman } from '~/helpers/duration';
import { LocalDate } from '~/components/LocalDate';
import { ServiceType } from '~/components/ServiceType';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';

const GET_EXAMS = `
  SELECT
    e.uid
  , patient->>'name' as patient_name
  , es.created_at as report_signed_date
  , service_type
  , options->>'duration' as duration
  FROM exams_view e
  JOIN exams_status es ON e.uid = es.exam
  WHERE e.invoice = $[invoice] and es.status = 'report_signed'
  ORDER BY e.service_type, e.created_at
`;

interface GetExamsVars {
  invoice: string;
}

interface Entity {
  uid: string;
  patient_name: string;
  service_type: string;
  duration: number;
  report_signed_date: Date;
}

type Props = { invoice: string };

const RESULTS_PER_PAGE = 10;

export const ExamCard: React.FC<Props> = ({ invoice }) => {
  const { rows, totalCount, loading, paginate } = useQueryPaginated<
    Entity,
    GetExamsVars
  >(GET_EXAMS, {
    variables: { invoice },
    pagination: { perPage: RESULTS_PER_PAGE },
    skip: !invoice,
  });

  function onChange(pagination: TablePaginationConfig) {
    paginate({ page: pagination.current, perPage: pagination.pageSize });
  }

  return (
    <Card title="Exams">
      <Table
        loading={loading}
        onChange={onChange}
        dataSource={rows}
        pagination={{
          total: totalCount,
          defaultPageSize: RESULTS_PER_PAGE,
        }}
        columns={[
          {
            width: 100,
            title: 'UID',
            dataIndex: 'uid',
            render: (uid: string) => (
              <Link to={`/exams/${uid}`}>
                <span style={{ fontFamily: 'monospace' }}>{uid.split('-')[0]}</span>
              </Link>
            ),
          },
          {
            title: 'Patient Name',
            dataIndex: 'patient_name',
          },
          {
            title: 'Service Type',
            dataIndex: 'service_type',
            render: (service_type: string) => <ServiceType>{service_type}</ServiceType>,
          },
          {
            title: 'Duration',
            dataIndex: 'duration',
            render: (duration: number) => durationHuman(duration),
          },
          {
            title: 'Report Signed Date',
            dataIndex: 'report_signed_date',
            render: (it) => <LocalDate datetime={it} />,
          },
        ]}
      />
    </Card>
  );
};
