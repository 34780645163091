import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'antd';
import dayjs from 'dayjs';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { useQuery } from '~/dataSource/useQuery';

const GET_EXAM_WEEKLY = `
  WITH counters AS (
    SELECT
      count(*)::int "total",
      date_trunc('month', etv.report_signed) "id"
    FROM exams_timers_view etv
    JOIN exams e ON e.uid = etv.exam
    WHERE etv.report_signed >= current_date - INTERVAL '1 year'
    AND e.location <> '5d4f8fe8-f60c-44c3-9f80-ee27f65a6c85'
    GROUP BY 2
  ), months AS (
    SELECT
      date_trunc('month', date) "id"
    FROM generate_series(
      current_date - INTERVAL '1 year',
      current_date,
      '1 month'
    ) AS g(date)
  )
  SELECT m.id "date", COALESCE(c.total, 0) "total"
  FROM months m
  LEFT JOIN counters c ON c.id = m.id
`;

type Entity = {
  date: string;
  total: number;
};

const CURRENT_COLOR = '#389e0d';

type Props = {
  title: string;
  status: string;
};

export const Year: React.FC<Props> = ({ title, status }) => {
  const [width, setWidth] = useState<number>(0);
  const chart = useRef<HTMLDivElement>(null);

  const { data } = useQuery<Entity[]>(GET_EXAM_WEEKLY, {
    variables: { status },
  });

  useEffect(() => {
    function updateWidth() {
      chart.current && setWidth(chart.current.getBoundingClientRect().width);
    }

    window.addEventListener('resize', updateWidth);

    setTimeout(updateWidth, 0);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [chart, data]);

  return (
    <Card title={title}>
      <div ref={chart} style={{ maxWidth: '100vw' }}>
        <VictoryChart
          theme={VictoryTheme.material}
          width={width}
          height={350}
          domainPadding={{ y: 5 }}
          padding={{
            top: 20,
            left: 70,
            right: 20,
            bottom: 100,
          }}
          containerComponent={<VictoryVoronoiContainer responsive={false} />}
        >
          <VictoryLine
            data={data}
            x="date"
            y="total"
            style={{ data: { stroke: CURRENT_COLOR } }}
          />

          <VictoryScatter
            data={data}
            x="date"
            y="total"
            labelComponent={<VictoryTooltip />}
            labels={({ datum }: { datum: Entity }) =>
              `${dayjs(datum.date).format('YYYY/MM')}: ${datum.total}`
            }
            style={{ data: { fill: CURRENT_COLOR } }}
          />

          <VictoryAxis
            tickFormat={(x) => dayjs(x).format('YYYY/MM')}
            style={{
              tickLabels: {
                angle: -45,
                verticalAnchor: 'middle',
                textAnchor: 'end',
              },
            }}
          />

          <VictoryAxis dependentAxis tickFormat={(it) => it.toFixed(0)} />
        </VictoryChart>
      </div>
    </Card>
  );
};
