import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Divider, Form, Row, message } from 'antd';
import { useMutation } from '~/dataSource/useMutation';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { PageHeader } from '~/components/PageHeader';
import { OperatorList } from '../components/OperatorList';
import { PhysicianForm } from '../components/PhysicianForm';
import { LoginCredentials } from '../components/LoginCredentials';

const GET_PERSON = `
  SELECT
    p.uid
  , name
  , document
  , document_type "documentType"
  , u.username
  FROM physicians p
  LEFT JOIN users u ON u.person = p.uid AND u.username IS NOT NULL
  WHERE p.uid = $[uid]
`;

interface Entity {
  uid: string;
  name: string;
  document: string;
  documentType: string;
  username: string;
}

interface GetPhysicianArgs {
  uid: string;
}

interface UpdatePhysicianInput {
  name: string;
  status: string;
  parent?: string;
}

export const EditPhysician: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  if (!uid) return null;

  const { data } = useQueryEntity<Entity, GetPhysicianArgs>(GET_PERSON, {
    variables: { uid },
    skip: !uid,
  });

  const [form] = Form.useForm<Entity>();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const { run: updatePhysician, loading } = useMutation<
    undefined,
    UpdatePhysicianInput
  >(`physicians/${uid}`, { method: 'put' });

  function onFinish(input: UpdatePhysicianInput) {
    updatePhysician(input)
      .then(() => {
        message.success('Person updated with success');
        navigate(-1);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }

  return (
    <>
      <PageHeader title={`Person: ${data?.name || ''}`} onBack={() => navigate(-1)} />
      <Divider />
      <Row gutter={18}>
        <Col span={14}>
          <Card title="Person Information">
            <PhysicianForm loading={loading} form={form} onFinish={onFinish} />
          </Card>
        </Col>
        <Col span={10}>
          <Card title="Login Credentials">
            <LoginCredentials person={data} />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Card title="Organization Connections" bodyStyle={{ margin: 0, padding: 0 }}>
        <OperatorList physician={uid} />
      </Card>
    </>
  );
};
