import { FC, useState } from 'react';
import {
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Descriptions,
  Typography,
  message,
} from 'antd';
import { useMutation } from '~/dataSource/useMutation';

interface PhysicianSignUpInput {
  organization: string;
  name: string;
  username: string;
  document: string;
  documentType: string;
}

interface PhysicianSignUpResponse {
  temporaryPassword: string;
}

type Props = {
  visible: boolean;
  organization: string;
  onCancel: (created: boolean) => void;
};

export const CreatePhysicianModal: FC<Props> = ({
  organization,
  visible,
  onCancel,
}) => {
  const [temporaryPassword, setTempPassword] = useState('');
  const [form] = Form.useForm();

  const { run: physicianSignUp, loading } = useMutation<
    PhysicianSignUpResponse,
    PhysicianSignUpInput
  >('physicians/sign-up');

  async function onFinish(input: PhysicianSignUpInput) {
    return physicianSignUp(input)
      .then((response) => {
        message.success('Physician sign up with success');
        return response;
      })
      .catch(() => {
        throw 'Something went wrong!';
      });
  }

  return (
    <Modal
      destroyOnClose
      title="Create New Account"
      okText={temporaryPassword ? 'Close' : 'Save'}
      okButtonProps={{
        loading,
      }}
      open={visible}
      onCancel={() => {
        onCancel(false);
        setTempPassword('');
        form.resetFields();
      }}
      onOk={() => {
        if (temporaryPassword) {
          onCancel(true);
          setTempPassword('');
          form.resetFields();
          return;
        }

        form
          .validateFields()
          .then((input: PhysicianSignUpInput) => onFinish(input))
          .then((response) => {
            setTempPassword(response.temporaryPassword);
          })
          .catch((err) => message.error(err.message));
      }}
    >
      {temporaryPassword ? (
        <Descriptions layout="vertical" title="Login Info">
          <Descriptions.Item label="Username">
            <Typography.Text copyable>{form.getFieldValue('username')}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Temporary Password">
            <Typography.Text copyable>{temporaryPassword}</Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Form layout="vertical" form={form}>
          <Form.Item name="organization" hidden initialValue={organization}>
            <Input />
          </Form.Item>

          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Document" name="document" rules={[{ required: true }]}>
            <Input
              addonBefore={
                <Form.Item noStyle name="documentType" initialValue="crm">
                  <Select>
                    <Select.Option value="crm">CRM</Select.Option>
                    <Select.Option value="cpf">CPF</Select.Option>
                  </Select>
                </Form.Item>
              }
            />
          </Form.Item>

          <Divider orientation="left">Login</Divider>

          <Form.Item label="Username" name="username" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
