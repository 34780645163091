import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PageHeader } from '~/components/PageHeader';
import { InvoiceDetails } from '../components/InvoiceDetails';

export const ViewInvoice: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  if (!uid) return null;

  return (
    <>
      <PageHeader onBack={() => navigate(-1)} title="Invoice" />
      <InvoiceDetails uid={uid} />
    </>
  );
};
