import { FC, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, Descriptions, Divider, Form, message } from 'antd';
import { PageHeader } from '~/components/PageHeader';
import { useMutation } from '~/dataSource/useMutation';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { LocationForm } from '../components/LocationForm';

const GET_LOCATION = `
  SELECT
    l.uid,
    l.name,
    l.address,
    l.city,
    l.state,
    l.postal_code "postalCode",
    json_build_object(
      'uid', o.uid,
      'name', o.name
    ) "organization"
  FROM locations l
  JOIN organizations o ON o.uid = l.organization
  WHERE l.uid = $[uid]
`;

interface Entity {
  uid: string;
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  organization: {
    uid: string;
    name: string;
  };
}

interface GetLocationArgs {
  uid?: string;
}

interface UpdateLocationInput {
  uid: string;
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
}

export const EditLocation: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  const { data } = useQueryEntity<Entity, GetLocationArgs>(GET_LOCATION, {
    variables: { uid },
    skip: !uid,
  });

  const [form] = Form.useForm<UpdateLocationInput>();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const { run: updateLocation, loading } = useMutation<undefined, UpdateLocationInput>(
    `locations/${uid}`,
    { method: 'put' }
  );

  function onFinish(data: UpdateLocationInput) {
    updateLocation(data)
      .then(() => {
        message.success('Location updated with success');
        navigate(-1);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }

  return (
    <Card>
      <PageHeader title={`Location: ${data?.name || ''}`} onBack={() => navigate(-1)} />
      <Divider />

      <LocationForm loading={loading} form={form} onFinish={onFinish} />
      <Divider />

      <Descriptions>
        <Descriptions.Item label="Organization">
          <Link to={`/organizations/${data?.organization?.uid}`}>
            {data?.organization?.name}
          </Link>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
