import { FunctionComponent, useEffect, useState } from 'react';
import { Alert, Card, Typography } from 'antd';
import { login } from '~/models/user';
import { SignIn } from './components/SignIn';

export const Login: FunctionComponent = () => {
  const [error, setError] = useState('');

  const onSignIn = () => {
    setError('');
    login();
  };

  useEffect(() => {
    const params = window.location.search;
    if (params.includes('errorMessage')) {
      setError(decodeURIComponent(params.split('errorMessage=')[1]));
    }
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        style={{
          width: '40rem',
          gap: '6',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography.Title>Quoretech Backoffice</Typography.Title>
        </div>

        <SignIn onSubmit={onSignIn} />

        {error && <Alert message={error} type="error" />}
      </Card>
    </div>
  );
};
