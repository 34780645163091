import { FC } from 'react';
import { Input, Select } from 'antd';
import { BaseForm, BaseFormProps, FormItem } from '~/components/BaseForm';
import { useQuery } from '~/dataSource/useQuery';
import { ServiceTypeOptions } from '~/components/ServiceType';

const GET_ORGANIZATIONS_OPTIONS = `
  SELECT
    uid
  , name
  FROM organizations
`;

type GetOrganizationOptionsResponse = {
  uid: string;
  name: string;
}[];

export const OrganizationForm: FC<BaseFormProps> = (props) => {
  const selectOptions = useQuery<GetOrganizationOptionsResponse>(
    GET_ORGANIZATIONS_OPTIONS
  );

  function onParentClear() {
    props.form?.setFieldValue('billedInParent', false);
  }

  return (
    <BaseForm {...props}>
      <FormItem name="uid" hidden>
        <Input />
      </FormItem>

      <FormItem label="Parent" name="parent">
        <Select
          allowClear
          onClear={onParentClear}
          disabled={selectOptions.loading}
          options={selectOptions.data
            ?.sort((a, b) =>
              a.name.localeCompare(b.name, undefined, {
                usage: 'sort',
                ignorePunctuation: true,
              })
            )
            .map((option) => ({
              value: option.uid,
              label: option.name,
            }))}
        />
      </FormItem>

      <FormItem label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </FormItem>

      <FormItem label="Legal Name" name="legalName" rules={[{ required: true }]}>
        <Input />
      </FormItem>

      <FormItem label="Account Name" name="accountName" rules={[{ required: true }]}>
        <Input />
      </FormItem>

      <FormItem label="Document" name="document" rules={[{ required: true }]}>
        <Input />
      </FormItem>

      <FormItem label="Status" name="status" initialValue="active">
        <Select>
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="inactive">Inactive</Select.Option>
        </Select>
      </FormItem>

      <FormItem label="Service Type" name="serviceType" initialValue="essential">
        <Select
          options={Array.from(ServiceTypeOptions)
            .filter(([key]) => !['daas', 'taas', 'essential'].includes(key))
            .map(([value, { label }]) => ({ value, label }))}
        />
      </FormItem>
    </BaseForm>
  );
};
