import { Tabs } from 'antd';
import { ReportPreferencesTab } from './forms/ReportPreferences';
import { ContactPreferencesTab } from './forms/ContactPreferences';
import { ProcessingPreferencesTab } from './forms/ProcessingPreferences';
import { VersionPreferencesTab } from './forms/VersionPreferences';

type Props = {
  loading: boolean;
  organization: string;
};

export const OrganizationPreferences: React.FC<Props> = ({ organization, loading }) => {
  return (
    <Tabs
      type="line"
      aria-disabled={loading}
      items={[
        {
          key: 'report',
          label: 'Report Preferences',
          children: <ReportPreferencesTab organization={organization} />,
        },
        {
          key: 'processing',
          label: 'Processing Preferences',
          children: <ProcessingPreferencesTab organization={organization} />,
        },
        {
          key: 'contact',
          label: 'Contact Preferences',
          children: <ContactPreferencesTab organization={organization} />,
        },
        {
          key: 'version',
          label: 'Version Preferences',
          children: <VersionPreferencesTab organization={organization} />,
        },
      ]}
    />
  );
};
