import { FunctionComponent } from 'react';
import { Button, Divider, Descriptions, Card } from 'antd';
import { PageHeader } from '~/components/PageHeader';
import { getCurrent, logout } from '~/models/user';

export const Profile: FunctionComponent = () => {
  const onLogout = () => {
    logout().finally(() => (window.location.href = '/'));
  };

  return (
    <Card>
      <PageHeader title="My Profile" />
      <Divider />

      <Descriptions>
        <Descriptions.Item label="Username">{getCurrent().username}</Descriptions.Item>
      </Descriptions>

      <Divider />
      <Button type="primary" danger onClick={onLogout}>
        Logout
      </Button>
    </Card>
  );
};
