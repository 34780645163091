import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Protected } from '~/components/Protected';
import { EditDevice } from './routes/EditDevice';
import { ListQuoreones } from './routes/ListQuoreones';

export const QuoreOnesRoutes: FC = () => {
  return (
    <Protected scope="admin:devices:list">
      <Routes>
        <Route path="/" element={<ListQuoreones />} />
        <Route path=":uid" element={<EditDevice />} />
      </Routes>
    </Protected>
  );
};
