import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Divider, Form, message } from 'antd';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { PageHeader } from '~/components/PageHeader';
import { useMutation } from '~/dataSource/useMutation';
import { DockingStationForm } from '../components/DockingStationForm';

const DEBUG_FLAG = 1 << 4;

const GET_DOCKING_STATION = `
  SELECT
    d.uid
  , d.serial_number
  , d.model
  , d.software_version
  , COALESCE(d.preferences->>'log_level', '0')::int log_level
  , l.uid "location_uid"
  , l.name "location_name"
  FROM docking_stations d
  LEFT JOIN locations l ON l.uid = d.location
  WHERE d.uid = $[uid]
`;

interface FormValues {
  serial_number: string;
  model: string;
  software_version: string;
  location: {
    key: string;
    label: string;
    value: string;
  };
  logLevel: number;
  debugging: boolean;
}

interface Entity {
  uid: string;
  serial_number: string;
  model: string;
  software_version: string;
  location_uid: string;
  location_name: string;
  log_level: number;
}

interface GetDockingStationArgs {
  uid?: string;
}

interface UpdateDockingStationInput {
  serialNumber: string;
  model: string;
  softwareVersion: string;
  location: string;
  logLevel: number;
}

export const EditDockingStation: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  const { data } = useQueryEntity<Entity, GetDockingStationArgs>(GET_DOCKING_STATION, {
    variables: { uid },
    skip: !uid,
  });

  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (data) {
      const logLevel = Math.max(0, data.log_level - DEBUG_FLAG);
      const debugging = data.log_level >= DEBUG_FLAG;

      form.setFieldsValue({
        ...data,
        logLevel,
        debugging,
        location: {
          key: data.location_uid,
          label: data.location_name,
          value: data.location_uid,
        },
      });
    }
  }, [data]);

  const { run: updateDockingStation, loading } = useMutation<
    undefined,
    UpdateDockingStationInput
  >(`dockings/${uid}`, { method: 'put' });

  function onFinish(formValues: FormValues) {
    updateDockingStation({
      serialNumber: formValues.serial_number,
      model: formValues.model,
      softwareVersion: formValues.software_version,
      location: formValues.location.value,
      logLevel: formValues.logLevel + (formValues.debugging ? DEBUG_FLAG : 0),
    })
      .then(() => {
        message.success('Docking Station updated with success');
        navigate(-1);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }

  return (
    <Card>
      <PageHeader
        title={`Docking Station: ${data?.serial_number || ''}`}
        onBack={() => navigate(-1)}
      />
      <Divider />

      <DockingStationForm loading={loading} form={form} onFinish={onFinish} />
    </Card>
  );
};
