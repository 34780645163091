import { FC } from 'react';
import { Input, Select } from 'antd';
import { BaseForm, BaseFormProps, FormItem } from '~/components/BaseForm';

export const PhysicianForm: FC<BaseFormProps> = (props) => {
  return (
    <BaseForm {...props}>
      <FormItem
        label="Name"
        name="name"
        rules={[{ required: true, message: 'please input physician name' }]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="Document"
        name="document"
        rules={[{ required: true, message: 'please input physician document' }]}
      >
        <Input
          addonBefore={
            <FormItem noStyle name="documentType" initialValue="crm">
              <Select>
                <Select.Option value="crm">CRM</Select.Option>
                <Select.Option value="cpf">CPF</Select.Option>
              </Select>
            </FormItem>
          }
        />
      </FormItem>
    </BaseForm>
  );
};
