import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { MainLayout } from './Layout/MainLayout';
import { Homepage } from './Homepage/Homepage';
import { Profile } from './Profile/Profile';
import { ExamsRoutes } from './Exams/ExamsRoutes';
import { DevicesRoutes } from './Devices/DevicesRoutes';
import { InvoicesRoutes } from './Invoices/InvoicesRoutes';
import { PatientsRoutes } from './Patients/PatientsRoutes';
import { LocationsRoutes } from './Locations/LocationsRoutes';
import { PhysiciansRoutes } from './Physicians/PhysiciansRoutes';
import { OrganizationsRoutes } from './Organizations/OrganizationsRoutes';
import { PoliciesRoutes } from './Policies/PoliciesRoutes';

export const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/patients/*" element={<PatientsRoutes />} />
          <Route path="/exams/*" element={<ExamsRoutes />} />
          <Route path="/organizations/*" element={<OrganizationsRoutes />} />
          <Route path="/people/*" element={<PhysiciansRoutes />} />
          <Route path="/devices/*" element={<DevicesRoutes />} />
          <Route path="/locations/*" element={<LocationsRoutes />} />
          <Route path="/invoices/*" element={<InvoicesRoutes />} />
          <Route path="/policies/*" element={<PoliciesRoutes />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
};
