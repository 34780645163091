import { FC } from 'react';
import { Card, Descriptions, Divider, Space } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '~/components/PageHeader';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { generateHostLink } from '~/helpers/datadog';
import { generateTicketLink } from '~/helpers/desk';

const GET_DOCKING_STATION = `
  SELECT
    ds.serial_number
  , ds.model
  , ds.software_version
  , ds.preferences
  , zds.ticket_id
  , json_build_object(
    'uid', l.uid,
    'name', l.name
  ) "location"
  , json_build_object(
    'uid', o.uid,
    'name', o.name
  ) "organization"
  FROM docking_stations ds
  LEFT JOIN zoho_docking_stations zds ON zds.docking_station = ds.uid
  LEFT JOIN locations l on l.uid = ds.location
  LEFT JOIN organizations o ON o.uid = l.organization
  WHERE ds.uid = $[uid]
`;

interface Entity {
  serial_number: string;
  software_version: string;
  model: string;
  ticket_id: string;
  location: {
    uid: string;
    name: string;
  };
  organization: {
    uid: string;
    name: string;
  };
}

interface QueryArgs {
  uid?: string;
}

export const ViewDockingStation: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  const { data } = useQueryEntity<Entity, QueryArgs>(GET_DOCKING_STATION, {
    variables: { uid },
    skip: !uid,
  });

  return (
    <Card>
      <PageHeader
        title={`Docking Station: ${data?.serial_number || uid}`}
        onBack={() => navigate(-1)}
        extra={<Link to="edit">Edit</Link>}
      />
      <Divider />

      <Descriptions>
        <Descriptions.Item label="Model">{data?.model}</Descriptions.Item>

        <Descriptions.Item label="Serial Number">
          {data?.serial_number}
        </Descriptions.Item>

        <Descriptions.Item label="Software Version">
          {data?.software_version}
        </Descriptions.Item>

        <Descriptions.Item label="Location">
          <Space>
            <Link to={`/organizations/${data?.organization?.uid}`}>
              {data?.organization?.name}
            </Link>
            <span>&gt;</span>
            <Link to={`/locations/${data?.location?.uid}`}>{data?.location?.name}</Link>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Desk Ticket Id">
          {data?.ticket_id ? (
            <a
              key="openZohoDeskLink"
              href={generateTicketLink(data.ticket_id)}
              target="_blank"
              rel="noopener noparent"
            >
              {data?.ticket_id}
            </a>
          ) : (
            '-'
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Datadog Logs">
          {data?.serial_number ? (
            <a
              key="openZohoDeskLink"
              href={generateHostLink([data.serial_number])}
              target="_blank"
              rel="noopener noparent"
            >
              View Logs
            </a>
          ) : (
            '-'
          )}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
