import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Protected } from '~/components/Protected';
import { EditDockingStation } from './routes/EditDockingStation';
import { ViewDockingStation } from './routes/ViewDockingStation';
import { ListDockingStations } from './routes/ListDockingStations';

export const DockingStationsRoutes: FC = () => {
  return (
    <Protected scope="admin:dockings:list">
      <Routes>
        <Route path="/" element={<ListDockingStations />} />
        <Route path=":uid" element={<ViewDockingStation />} />
        <Route path=":uid/edit" element={<EditDockingStation />} />
      </Routes>
    </Protected>
  );
};
