import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ViewInvoice } from './routes/ViewInvoice';

export const InvoicesRoutes: FC = () => {
  return (
    <Routes>
      <Route path=":uid" element={<ViewInvoice />} />
    </Routes>
  );
};
