import { useState, useEffect, useCallback } from 'react';
import { request } from './client';
import { parse } from './sql';

type QueryConfig<T> = {
  skip?: boolean;
  variables?: T;
};

type QueryState<T> = {
  loading: boolean;
  data?: T;
};

type UseQueryEntityExt<T> = { refetch: (vars?: T) => void };

export function useQueryEntity<TEntity, TVars extends Record<string, any>>(
  query: string,
  config?: QueryConfig<TVars>
): QueryState<TEntity> & UseQueryEntityExt<TVars> {
  const [state, setState] = useState<QueryState<TEntity>>({ loading: false });
  const [variables, setVariables] = useState<TVars | undefined>(config?.variables);
  const [version, setVersion] = useState('initial');

  const refetch = useCallback((newVariables?: TVars) => {
    setVariables(newVariables || variables);
    setVersion(new Date().toISOString());
  }, []);

  useEffect(() => {
    if (config?.skip) {
      return;
    }

    setState((state) => ({ ...state, loading: true }));

    request<{ rows: TEntity[] }>(`/query`, {
      method: 'POST',
      body: JSON.stringify(parse(query, { ...variables, ...config?.variables })),
    }).then(({ rows: [data] }) => {
      setState({ loading: false, data });
    });
  }, [variables, config?.skip, version]);

  return {
    ...state,
    refetch,
  };
}
