import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Card, Descriptions, Typography } from 'antd';
import { useElementWidth } from '~/hooks/useElementWidthHook';
import { Duration } from '~/helpers/duration';
import { getLocalDate } from '~/components/LocalDate';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

const GET_BATTERY_TIMELINE = `
  SELECT *
  FROM metrics.battery_levels
  WHERE exam = $[uid]
`;

type Entity = {
  exam: string;
  created_at: string;
  information: {
    duration_s: number;
    drainage_per_hour: number;
    final_level: number;
    initial_level: number;
    level_interpolation: {
      time: string[];
      battery_level: number[];
    };
  };
};

class InformationDao {
  constructor(public props?: Entity) {}

  get initialLevel() {
    return this.getRound('initial_level');
  }

  get finalLevel() {
    return this.getRound('final_level');
  }

  get drainage() {
    const value = this.props?.information.drainage_per_hour;
    if (value === 0) {
      return 0;
    }
    return value ? Number(value.toPrecision(3)) : null;
  }

  get duration() {
    const value = this.props?.information.duration_s;
    return value ? Duration.fromSeconds(value).toString() : '-';
  }

  get estimate() {
    const value = this.props?.information.drainage_per_hour;
    if (value === 0) {
      return 'Infinity';
    }
    return value ? Duration.fromHours(100 / value).toString(false) : null;
  }

  get color() {
    if (!this.drainage) {
      return 'secondary';
    }

    if (this.drainage <= 0.5) {
      return 'success';
    }

    if (this.drainage <= 0.55) {
      return 'warning';
    }

    return 'danger';
  }

  private getRound(
    prop: keyof Pick<Entity['information'], 'final_level' | 'initial_level'>
  ) {
    const value = this.props?.information?.[prop];
    if (value === null || value === undefined) {
      return null;
    }

    return Math.round(value);
  }
}

type Props = {
  uid: string;
};

const AREA_COLOR = '#1d2933';

export function ExamBatteryTimeline({ uid }: Props) {
  const { data } = useQueryEntity<Entity, Props>(GET_BATTERY_TIMELINE, {
    variables: { uid },
    skip: !uid,
  });

  const results = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.information.level_interpolation.time.reduce<
      {
        x: string;
        y: number;
      }[]
    >((agg, x, idx) => {
      agg.push({
        x,
        y: Math.round(data.information.level_interpolation.battery_level[idx]),
      });
      return agg;
    }, []);
  }, [data]);

  const info = useMemo(() => new InformationDao(data), [data]);

  const { width, container } = useElementWidth();

  return (
    <Card type="inner" title="Exam Battery Timeline" ref={container}>
      {/* <details>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </details> */}
      <Descriptions column={24}>
        <Descriptions.Item span={8} label="Extraction Date">
          {getLocalDate(data?.created_at)}
        </Descriptions.Item>

        <Descriptions.Item span={8} label="Initial Level">
          {info.initialLevel}%
        </Descriptions.Item>

        <Descriptions.Item span={8} label="Final Level">
          {info.finalLevel}%
        </Descriptions.Item>

        <Descriptions.Item span={8} label="Drainage Period">
          {info.duration}
        </Descriptions.Item>

        <Descriptions.Item span={8} label="Drainage per hour">
          <Typography.Text type={info.color}>{info.drainage}%</Typography.Text>
        </Descriptions.Item>

        <Descriptions.Item span={8} label="Drainage Estimation">
          <Typography.Text type={info.color}>{info.estimate}</Typography.Text>
        </Descriptions.Item>
      </Descriptions>

      <div>
        <VictoryChart
          theme={VictoryTheme.material}
          width={width}
          height={400}
          padding={{
            top: 20,
            left: 70,
            right: 20,
            bottom: 100,
          }}
          domainPadding={{
            y: 10,
          }}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryArea
            name="Battery Level"
            data={results}
            style={{
              data: { stroke: AREA_COLOR, fill: AREA_COLOR, fillOpacity: 0.3 },
            }}
          />

          <VictoryAxis
            fixLabelOverlap
            style={{
              tickLabels: {
                angle: -45,
                verticalAnchor: 'middle',
                textAnchor: 'end',
              },
            }}
            tickFormat={(x: string) => dayjs(x + 'Z').format('DD/MM HH:mm:ss')}
          />

          <VictoryAxis
            tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            tickFormat={(y: string) => `${y}%`}
            dependentAxis
          />

          <VictoryScatter
            labelComponent={<VictoryTooltip />}
            data={results}
            labels={({ datum }: { datum: { y: number; x: string } }) =>
              `Battery Level: ${datum.y.toFixed(0)}%\nTimestamp: ${dayjs(
                datum.x + 'Z'
              ).format('DD/MM HH:mm:ss')}`
            }
            style={{ data: { fill: AREA_COLOR } }}
          />
        </VictoryChart>
      </div>
    </Card>
  );
}
