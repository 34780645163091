import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { useQuery } from '~/dataSource/useQuery';
import { ServiceTypeOptions } from '~/components/ServiceType';

const GET_ORGANIZATIONS_STATISTICS = `
  SELECT
    COUNT(*)::int4
  , service_type::text
  FROM organizations_view
  WHERE status = 'active'
  GROUP BY 2
  UNION
  SELECT
    COUNT(*)::int4
  , 'total_orgs' service_type
  FROM organizations o
  WHERE status = 'active'
`;

type GetStatisticsResponse = { count: number; service_type: string }[];

export const OrganizationsStatistics: React.FC = () => {
  const { data, loading } = useQuery<GetStatisticsResponse>(
    GET_ORGANIZATIONS_STATISTICS
  );

  const colorOptions = new Map()
    .set('orange', { background: '#fff7e6', border: '#ffd591', font: 'd46b08' })
    .set('green', { background: '#f6ffed', border: '#b7eb8f', font: '#389e0d' })
    .set('cyan', { background: '#e6fffb', border: '#87e8de', font: '#08979c' })
    .set('blue', { background: '#e6f4ff', border: '#91caff', font: '#0958d9' })
    .set('purple', { background: '#f9f0ff', border: '#d3adf7', font: '#531dab' });

  return (
    <Row gutter={[8, { xs: 4, sm: 8 }]}>
      <Col sm={12} xl={6}>
        <Card>
          <Statistic
            loading={loading}
            title="Total Active"
            value={data?.find((e) => e.service_type === 'total_orgs')?.count}
          />
        </Card>
      </Col>

      {Array.from(ServiceTypeOptions, ([key, option]) => {
        const color = colorOptions.get(option.color);
        return (
          <Col sm={12} xl={6} key={key}>
            <Card
              style={{
                background: color.background,
                borderColor: color.border,
              }}
            >
              <Statistic
                loading={loading}
                title={option.label}
                value={data?.find((e) => e.service_type === key)?.count}
                valueStyle={{ color: color.font }}
              />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};
