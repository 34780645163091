import React from 'react';
import { Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { generateHostLink } from '~/helpers/datadog';
import { useQuery } from '~/dataSource/useQuery';

const GET_QUOREONE_DEVICES = `
  SELECT
    uid
  , status
  , serial_number
  , firmware_version
  , mac_address
  , location
  FROM devices_view
  WHERE organization->>'uid' = $[uid]
  ORDER BY serial_number ASC
`;

const GET_DOCKING_DEVICES = `
  SELECT
    d.uid
  , model
  , serial_number
  , software_version
  , row_to_json(l) "location"
  FROM docking_stations d
  JOIN locations l on l.uid = d.location
  JOIN organizations o ON o.uid = l.organization
  WHERE o.uid = $[uid]
  ORDER BY serial_number ASC
`;

interface QuoreoneEntity {
  uid: string;
  status: string;
  serial_number: string;
  firmware_version: string;
  mac_address: string;
  location: {
    uid: string;
    name: string;
  };
}

interface DockingEntity {
  uid: string;
  model: string;
  serial_number: string;
  software_version: string;
  location: {
    uid: string;
    name: string;
  };
}

type Props = {
  organization: string;
};

export const DevicesTab: React.FC<Props> = ({ organization }) => {
  const quoreones = useQuery<QuoreoneEntity[]>(GET_QUOREONE_DEVICES, {
    skip: !organization,
    variables: { uid: organization },
  });

  const dockings = useQuery<DockingEntity[]>(GET_DOCKING_DEVICES, {
    skip: !organization,
    variables: { uid: organization },
  });

  const hosts = React.useMemo(() => {
    if (!dockings.data) {
      return '';
    }

    return generateHostLink(dockings.data.map((it) => it.serial_number));
  }, [dockings.data]);

  const filters = React.useMemo(() => {
    const options = new Map(
      quoreones.data?.map((it) => [it.location.uid, it.location.name])
    );
    return [...options.entries()].map(([value, text]) => ({ value, text }));
  }, [quoreones.data]);

  return (
    <>
      <Typography.Title level={5}>Uploader</Typography.Title>

      <Table<DockingEntity>
        footer={() => (
          <Space>
            Total of <strong>{dockings.data?.length}</strong>
            <span>•</span>
            <a href={hosts} target="_blank" rel="noopener noreferrer">
              View Logs
            </a>
          </Space>
        )}
        loading={dockings.loading}
        dataSource={dockings.data}
        pagination={{
          showSizeChanger: false,
          total: dockings.data?.length,
          defaultPageSize: 20,
        }}
        columns={[
          {
            width: 100,
            title: 'UID',
            dataIndex: 'uid',
            render: (uid: string) => (
              <Link to={`/devices/dockings/${uid}`}>
                <span style={{ fontFamily: 'monospace' }}>{uid.split('-')[0]}</span>
              </Link>
            ),
          },
          { title: 'Serial Number', dataIndex: 'serial_number' },
          { title: 'Software Version', dataIndex: 'software_version' },
          { title: 'Model', dataIndex: 'model' },
          {
            title: 'Location',
            dataIndex: 'location',
            render: (location: DockingEntity['location']) => (
              <Link to={`/locations/${location.uid}`}>{location.name}</Link>
            ),
            filters,
            onFilter: (value, record) => record.location.uid === value,
          },
          {
            title: 'Logs',
            dataIndex: 'serial_number',
            render: (serial_number: string) => (
              <a
                href={generateHostLink([serial_number])}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Logs
              </a>
            ),
          },
        ]}
      />

      <br />
      <Typography.Title level={5}>Quoreone</Typography.Title>

      <Table<QuoreoneEntity>
        footer={() => (
          <>
            Total of <strong>{quoreones.data?.length}</strong>
          </>
        )}
        rowKey="uid"
        loading={quoreones.loading}
        dataSource={quoreones.data}
        pagination={{
          showSizeChanger: true,
          total: quoreones.data?.length,
          defaultPageSize: 20,
        }}
        columns={[
          {
            width: 100,
            title: 'UID',
            dataIndex: 'uid',
            render: (uid: string) => (
              <Link to={`/devices/quoreones/${uid}`}>
                <span style={{ fontFamily: 'monospace' }}>{uid.split('-')[0]}</span>
              </Link>
            ),
          },
          { title: 'Serial Number', dataIndex: 'serial_number' },
          { title: 'Firmware Version', dataIndex: 'firmware_version' },
          { title: 'Mac Address', dataIndex: 'mac_address' },
          { title: 'Status', dataIndex: 'status' },
          {
            title: 'Location',
            dataIndex: 'location',
            render: (location: any) => (
              <Link to={`/locations/${location.uid}`}>{location.name}</Link>
            ),
            filters,
            onFilter: (value, record) => record.location.uid === value,
          },
        ]}
      />
    </>
  );
};
