import React from 'react';
import { Typography, theme } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

type Props = {
  title?: string;
  children?: React.ReactNode;
  onBack?: () => void;
  extra?: React.ReactNode;
};

export const PageHeader: React.FC<Props> = ({ title, children, extra, onBack }) => {
  const { token } = theme.useToken();

  return (
    <section
      style={{
        padding: `${token.paddingContentVertical}px ${token.paddingContentHorizontal}px`,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          {onBack && (
            <div
              onClick={onBack}
              style={{
                cursor: 'pointer',
                marginRight: token.marginSM,
                fontSize: token.fontSizeHeading4,
              }}
            >
              <ArrowLeftOutlined />
            </div>
          )}
          {title && (
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              {title}
            </Typography.Title>
          )}
        </div>
        <div>{extra}</div>
      </div>
      <div style={{ paddingTop: token.paddingContentVertical }}>{children}</div>
    </section>
  );
};
