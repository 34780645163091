import React from 'react';
import { Tag } from 'antd';

function getColor(status: string) {
  switch (status) {
    case 'missing_packets':
    case 'no_annotations_created':
      return 'red';

    case 'receiving_packets':
      return 'gold';

    case 'creating_quorelead':
    case 'creating_annotations':
      return 'orange';

    case 'annotations_created':
      return 'geekblue';

    case 'statistics_created':
      return 'blue';

    case 'ready_to_report':
    case 'ready_to_sign':
      return 'cyan';

    case 'report_signed':
      return 'green';

    case 'archived':
      return 'magenta';

    default:
      return;
  }
}

export const ExamStatus: React.FC<{ status: string }> = ({ status }) => {
  return <Tag color={getColor(status)}>{status}</Tag>;
};
