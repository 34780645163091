import { InputNumber, Select } from 'antd';
import { FormItem } from '~/components/BaseForm';
import { PreferencesForm } from '../abstract/PreferencesForm';

type Props = {
  organization: string;
};

export const ProcessingPreferencesTab: React.FC<Props> = ({ organization }) => (
  <PreferencesForm organization={organization} segment="processing">
    <FormItem
      label="Supraventricular Precocity"
      name="supraThreshold"
      rules={[{ required: true }]}
      initialValue={20}
    >
      <InputNumber min={1} max={100} addonAfter="%" />
    </FormItem>

    <FormItem
      label="Pause Duration"
      name="pauseDuration"
      rules={[{ required: true }]}
      initialValue={2_500}
    >
      <InputNumber min={100} max={10_000} step={100} addonAfter="ms" />
    </FormItem>

    <FormItem
      label="Heart Rate Criteria"
      name="heartRateCriteria"
      rules={[{ required: true }]}
      initialValue="normal_to_normal"
    >
      <Select
        options={[
          { value: 'any_to_normal', label: 'Any to Normal' },
          { value: 'normal_to_normal', label: 'Normal to Normal' },
          {
            value: 'normal_to_normal_clean',
            label: 'Normal to Normal Without Ectopics',
          },
        ]}
      />
    </FormItem>

    <FormItem
      label="Quorelead Version"
      name="quoreleadVersion"
      rules={[{ required: true }]}
      initialValue="v1"
    >
      <Select
        options={[
          { value: 'v1', label: 'Quorelead V1' },
          { value: 'v3', label: 'Quorelead V3' },
        ]}
      />
    </FormItem>
  </PreferencesForm>
);
