import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Descriptions, Row, Col, Button, Divider } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { LocalDate } from '~/components/LocalDate';
import { durationHuman } from '~/helpers/duration';
import { ExamService } from './ExamService';
import { ExamStatuses } from './ExamStatuses';
import { ExamPrescriber } from './ExamPrescriber';
import { ExamLocationCard } from './ExamLocationCard';
import { useViewExamContext } from '../contexts/ViewExamContext';

type Props = { uid: string };

export const Component: React.FC<Props> = ({ uid }) => {
  const { data, loading, refetch } = useViewExamContext();

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} xl={16}>
        <Card
          title="Exam"
          extra={[
            <Button
              size="small"
              key="refetch"
              icon={<ReloadOutlined spin={loading} />}
              onClick={() => refetch()}
            />,
          ]}
        >
          <Descriptions
            className="exam-descriptions"
            column={{
              xs: 1,
              sm: 2,
              lg: 3,
            }}
          >
            <Descriptions.Item label="Status">{data?.exam.status}</Descriptions.Item>
            <Descriptions.Item label="Reasons">
              {data?.exam.reasons.join()}
            </Descriptions.Item>
            <Descriptions.Item label="Symptoms">
              {data?.exam.symptoms.join()}
            </Descriptions.Item>
            <Descriptions.Item label="Duration">
              {durationHuman(data?.exam.options.duration || 0)}
            </Descriptions.Item>
            <Descriptions.Item label="Moved to Glacier">
              <LocalDate datetime={data?.exam.metadata.purged_at} />
            </Descriptions.Item>
            <Descriptions.Item label="Quorelead Version">
              {data?.exam.settings.quoreleadVersion ||
                data?.exam.settings.quorelead_version}
            </Descriptions.Item>
            <Descriptions.Item label="Quality Index">
              {data?.exam.metadata.qualityIndex}
            </Descriptions.Item>
            <Descriptions.Item label="Timezone">
              {data?.exam.metadata.timeZone}
            </Descriptions.Item>
            <Descriptions.Item label="Firmware Version">
              {data?.exam.metadata.firmwareVersion}
            </Descriptions.Item>
            <Descriptions.Item label="App Info" span={2}>
              {data?.exam.metadata.appVersion}
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">Service</Divider>
          <ExamService
            loading={loading}
            onUpdate={refetch}
            exam={{
              uid,
              status: data?.exam.status,
              service_type: data?.invoice?.service_type,
            }}
          />
        </Card>

        <br />
        <Card
          title="Patient"
          extra={<Link to={`/patients/${data?.patient.uid}`}>{data?.patient.uid}</Link>}
        >
          <Descriptions
            column={{
              xs: 1,
              sm: 2,
              lg: 3,
            }}
          >
            <Descriptions.Item label="Name">{data?.patient.name}</Descriptions.Item>
            <Descriptions.Item label="Document">
              {data?.patient.document_type.toUpperCase()} {data?.patient.document}
            </Descriptions.Item>
            <Descriptions.Item label="Birthdate">
              <LocalDate datetime={data?.patient.birthdate} format="DD/MM/YYYY" />
            </Descriptions.Item>
            <Descriptions.Item label="Sex">{data?.patient.sex}</Descriptions.Item>
            <Descriptions.Item label="Email">{data?.patient.email}</Descriptions.Item>

            <Descriptions.Item label="Height">
              {data?.exam.demographics.height} cm
            </Descriptions.Item>
            <Descriptions.Item label="Weight">
              {data?.exam.demographics.weight} Kg
            </Descriptions.Item>
            <Descriptions.Item label="Smoker">
              {data?.exam.demographics.smoker ? 'Yes' : 'No'}
            </Descriptions.Item>
            <Descriptions.Item label="Pacemaker">
              {data?.exam.demographics.pacemaker ? 'Yes' : 'No'}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <br />
        <Card
          title="Device"
          extra={
            data?.device?.uid ? (
              <Link to={`/devices/quoreones/${data.device.uid}`}>
                {data.device.uid}
              </Link>
            ) : (
              data?.device?.uid
            )
          }
        >
          <Descriptions
            column={{
              xs: 1,
              sm: 2,
              lg: 3,
            }}
          >
            <Descriptions.Item label="Serial Number">
              {data?.device?.serial_number}
            </Descriptions.Item>
            <Descriptions.Item label="MAC Address">
              {data?.device?.mac_address.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Firmware Version">
              {data?.device?.firmware_version}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <br />
        <ExamLocationCard
          exam={uid}
          location={data?.location}
          organization={data?.organization}
          onSuccess={() => refetch()}
        />
        <br />
        <Card title="Diagnosis" bodyStyle={{ whiteSpace: 'pre-wrap' }}>
          {data?.exam.diagnosis}
        </Card>
      </Col>

      <Col xs={24} xl={8}>
        <ExamStatuses
          exam={uid}
          statuses={data?.statuses}
          deletedStatuses={data?.deleted_statuses}
          onSuccess={() => refetch()}
        />
        <br />
        <ExamPrescriber exam={uid} data={data?.exam.prescriber} onSave={refetch} />
        <br />
        <Card title="Requestor/Creator">
          <Descriptions column={1}>
            <Descriptions.Item label="Name">{data?.requestor.name}</Descriptions.Item>
            <Descriptions.Item label="Document">
              {data?.requestor.document_type.toUpperCase()} {data?.requestor.document}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <br />
        <Card title="Technician/Reviewer">
          <Descriptions column={1}>
            <Descriptions.Item label="Name">{data?.technician?.name}</Descriptions.Item>
            <Descriptions.Item label="Document">
              {data?.technician?.document_type.toUpperCase()}{' '}
              {data?.technician?.document}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <br />
        <Card title="Reporter">
          <Descriptions column={1}>
            <Descriptions.Item label="Name">{data?.reporter?.name}</Descriptions.Item>
            <Descriptions.Item label="Document">
              {data?.reporter?.document_type.toUpperCase()} {data?.reporter?.document}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>
  );
};

Component.displayName = 'ExamDetails';

export const ExamDetails = React.memo(Component);
