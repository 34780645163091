import { FC } from 'react';
import { Form, Input, Modal, message } from 'antd';
import { useMutation } from '~/dataSource/useMutation';

interface CreateLocationInput {
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
}

type Props = {
  visible: boolean;
  organization: string;
  onCancel: (created: boolean) => void;
};

export const CreateLocationModal: FC<Props> = ({ organization, visible, onCancel }) => {
  const [form] = Form.useForm();

  const { run: updateLocation, loading } = useMutation<undefined, CreateLocationInput>(
    'locations'
  );

  async function onFinish(input: CreateLocationInput) {
    return updateLocation(input)
      .then((response) => {
        message.success('Location created with success');
        return response;
      })
      .catch(() => {
        throw 'Something went wrong!';
      });
  }

  return (
    <Modal
      destroyOnClose
      title="Add Location"
      okText="Save"
      okButtonProps={{
        loading,
      }}
      open={visible}
      onCancel={() => {
        onCancel(false);
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((input: CreateLocationInput) =>
            onFinish(input).then(() => {
              onCancel(true);
              form.resetFields();
            })
          )
          .catch((err) => message.error(err.message));
      }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item name="organization" hidden initialValue={organization}>
          <Input />
        </Form.Item>

        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Address" name="address" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="City" name="city" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="State" name="state" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Postal code" name="postalCode" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
