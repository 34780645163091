import { Input } from 'antd';
import { FormItem } from '~/components/BaseForm';
import { PreferencesForm } from '../abstract/PreferencesForm';

type Props = {
  organization: string;
};

export const VersionPreferencesTab: React.FC<Props> = ({ organization }) => (
  <PreferencesForm organization={organization} segment="version">
    <FormItem label="Quoreone Firmware Version" name="quoreoneFirmwareVersion">
      <Input />
    </FormItem>

    <FormItem label="Docking Software Version" name="dockingSoftwareVersion">
      <Input />
    </FormItem>
  </PreferencesForm>
);
