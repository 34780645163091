import React from 'react';
import { Button, Card, Descriptions, message, Space } from 'antd';
import { useMutation } from '~/dataSource/useMutation';

const initialState = {
  isEdit: false,
  isLoading: false,
};

type State = typeof initialState;

function reducer(state: State, action: 'edit' | 'submit' | 'reset'): State {
  switch (action) {
    case 'edit':
      return { ...state, isEdit: true };
    case 'submit':
      return { ...state, isLoading: true };
    case 'reset':
      return initialState;

    default:
      throw new Error('');
  }
}

type Props = {
  exam: string;
  data?: null | {
    name: string;
    document: string;
  };
  onSave: () => void;
};

export const ExamPrescriber: React.FC<Props> = ({ exam, data, onSave }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { run: updateExamPrescriber } = useMutation<
    string,
    {
      prescriber?: {
        name: string;
        document: string;
      };
    }
  >(`exams/${exam}/prescriber`, {
    method: 'put',
  });

  function onRemove() {
    updateExamPrescriber({})
      .then(() => {
        onSave?.();
        message.success('Exam prescriber removed with success!');
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong!');
      });
  }

  function onSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    dispatch('submit');
    const formData = new FormData(evt.currentTarget);

    const name = formData.get('name') as string;
    const document = formData.get('document') as string;

    updateExamPrescriber({
      prescriber: {
        name,
        document,
      },
    })
      .then(() => {
        onSave?.();
        message.success('Exam prescriber updated with success!');
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong!');
      })
      .finally(() => {
        dispatch('reset');
      });
  }

  const cta = state.isEdit
    ? [
        <Space key="isEdit">
          <Button
            key="cancel"
            size="small"
            onClick={() => dispatch('reset')}
            disabled={state.isLoading}
          >
            Cancel
          </Button>
          <Button
            key="save"
            size="small"
            type="primary"
            htmlType="submit"
            form="Prescriber_Form"
            loading={state.isLoading}
          >
            Save
          </Button>
        </Space>,
      ]
    : [
        <Space key="isNotEdit">
          <Button size="small" key="remove" onClick={onRemove} disabled={!data}>
            Remove
          </Button>
          <Button size="small" key="edit" onClick={() => dispatch('edit')}>
            Edit
          </Button>
        </Space>,
      ];

  return (
    <Card title="Prescriber" extra={cta}>
      <form id="Prescriber_Form" onSubmit={onSubmit}>
        <Descriptions column={1}>
          {state.isEdit ? (
            <>
              <Descriptions.Item label="Name">
                <input
                  style={{ marginTop: -2 }}
                  required
                  name="name"
                  className="ant-input ant-input-sm"
                  readOnly={state.isLoading}
                  defaultValue={data?.name}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Document">
                <input
                  style={{ marginTop: -2 }}
                  name="document"
                  className="ant-input ant-input-sm"
                  readOnly={state.isLoading}
                  defaultValue={data?.document}
                />
              </Descriptions.Item>
            </>
          ) : (
            <>
              <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
              <Descriptions.Item label="Document">{data?.document}</Descriptions.Item>
            </>
          )}
        </Descriptions>
      </form>
    </Card>
  );
};
