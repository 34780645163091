import React from 'react';
import { Button, Card, CardProps, Space } from 'antd';
import { getCurrent } from '~/models/user';

type Props = {
  scope: string;
  children?: React.ReactNode;
  isEditing?: boolean;
  onEditingChange?: (isEditing: boolean) => void;
  editChildren: React.ReactNode;
  cardProps?: Omit<CardProps, 'extra'>;
  actionProps?: {
    loading?: boolean;
    onSave?: (...args: any[]) => unknown;
  };
};

export const EditCard: React.FC<Props> = ({
  scope,
  children,
  actionProps,
  cardProps,
  editChildren,
  isEditing,
  onEditingChange,
}) => {
  const cta = isEditing
    ? [
        <Space key="editing">
          <Button
            size="small"
            onClick={() => onEditingChange?.(false)}
            disabled={actionProps?.loading}
          >
            Cancel
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => actionProps?.onSave?.()}
            loading={actionProps?.loading}
            disabled={actionProps?.loading}
          >
            Save
          </Button>
        </Space>,
      ]
    : getCurrent().can(scope)
    ? [
        <Button size="small" key="edit" onClick={() => onEditingChange?.(true)}>
          Edit
        </Button>,
      ]
    : null;

  return (
    <Card {...cardProps} extra={cta}>
      {isEditing ? editChildren : children}
    </Card>
  );
};
