import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Protected } from '~/components/Protected';
import { ViewOrganization } from './routes/ViewOrganization';
import { EditOrganization } from './routes/EditOrganization';
import { ListOrganizations } from './routes/ListOrganizations';
import { CreateOrganization } from './routes/CreateOrganization';

export const OrganizationsRoutes: FC = () => {
  return (
    <Protected scope="admin:organizations:list">
      <Routes>
        <Route path="/" element={<ListOrganizations />} />
        <Route path=":uid" element={<ViewOrganization />} />
        <Route path=":uid/edit" element={<EditOrganization />} />
        <Route path="new" element={<CreateOrganization />} />
      </Routes>
    </Protected>
  );
};
