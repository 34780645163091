import { Card } from 'antd';
import { FC } from 'react';
import { useQueryEntity } from '~/dataSource/useQueryEntity';

const GET_ZOHO_CONFIG = `
  SELECT
    *
  FROM zoho_projects
  WHERE organization = $[uid]
`;

type Props = {
  organization: string;
  loading?: boolean;
};

export const ZohoTab: FC<Props> = (props) => {
  const data = useQueryEntity(GET_ZOHO_CONFIG, {
    variables: { uid: props.organization },
  });
  return (
    <Card>
      <pre>{JSON.stringify(data.data, null, 2)}</pre>
    </Card>
  );
};
