import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Tabs, TabsProps } from 'antd';
import { getCurrent } from '~/models/user';
import { PageHeader } from '~/components/PageHeader';
import { ExamDetails } from '../components/ExamDetails';
import { ExamActions } from '../components/ExamActions';
import { ZohoDetails } from '../components/ZohoDetails';
import { NeomedDetails } from '../components/NeomedDetails';
import { ExamBatteryTimeline } from '../components/ExamBatteryTimeline';
import { ViewExamContextProvider } from '../contexts/ViewExamContext';

export const ViewExam: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  if (!uid) return null;

  const items = useMemo(() => {
    const user = getCurrent();

    const items: TabsProps['items'] = [
      {
        key: 'details',
        label: 'Details',
        children: <ExamDetails uid={uid} />,
      },
    ];

    // if (user.can('admin:exams:task:get')) {
    //   items.push({
    //     key: 'processing',
    //     label: 'Processing',
    //     children: <ProcessingDetails uid={uid} />,
    //   });
    // }

    // if (user.can('admin:exams:upload-progress:get')) {
    //   items.push({
    //     key: 'progress',
    //     label: 'Upload Progress',
    //     children: <ExamUploadProgress uid={uid} />,
    //   });
    // }

    if (user.can('admin:exams:battery:list')) {
      items.push({
        key: 'battery',
        label: 'Battery Timeline',
        children: <ExamBatteryTimeline uid={uid} />,
      });
    }

    if (user.can('admin:exams:zoho')) {
      items.push({
        key: 'zoho',
        label: 'Zoho',
        children: <ZohoDetails uid={uid} />,
      });
    }

    if (user.can('admin:exams:neomed')) {
      items.push({
        key: 'neomed',
        label: 'Neomed',
        children: <NeomedDetails uid={uid} />,
      });
    }

    return items;
  }, []);

  return (
    <ViewExamContextProvider uid={uid}>
      <PageHeader
        title="Exam Overview"
        onBack={() => navigate(-1)}
        extra={[<ExamActions uid={uid} key="actions" />]}
      />

      <Tabs type="card" items={items} />
    </ViewExamContextProvider>
  );
};
