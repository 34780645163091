import { getToken } from '../models/user';

const BASE_URL = process.env.REACT_APP_API_URL;

export const request = async <T>(path: string, options: RequestInit): Promise<T> => {
  const token = await getToken();

  const url = new URL(path.replace(/^\//, ''), BASE_URL);

  const response = await fetch(url.toString(), {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...options.headers,
    },
  });

  const type = response.headers.get('content-type');
  const hasBody = response.headers.get('content-length') !== '0';

  const data = !hasBody
    ? undefined
    : type?.includes('application/json')
    ? await response.json()
    : type?.includes('text/plain')
    ? response.text()
    : response;

  if (response.ok) {
    return data;
  }

  if (response.status === 400) {
    throw data;
  }

  throw response;
};

export const client = {
  async put<T = unknown>(path: string, body: unknown): Promise<T> {
    return request(path, {
      method: 'PUT',
      body:
        String(body) === '[object Object]'
          ? JSON.stringify(body)
          : (body as RequestInit['body']),
    });
  },

  async get<T = unknown>(path: string): Promise<T> {
    return request(path, {
      method: 'GET',
    });
  },
};

export function parseURL(path: string, variables?: Record<string, any>): string {
  return Object.entries(variables || {}).reduce(
    (f, [k, v]) => f.replace(`[${k}]`, v).replace(`{${k}}`, v),
    path
  );
}
