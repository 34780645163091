import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, TablePaginationConfig } from 'antd';
import { LocalDate } from '~/components/LocalDate';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';
import { CreatePhysicianModal } from './CreatePhysicianModal';

const GET_PHYSICIANS = `
  SELECT
    p.uid
  , p.name
  , p.document
  , p.document_type
  , u.username
  , o.created_at
  FROM operators o
  JOIN users u ON u.person = o.physician
  JOIN physicians p ON p.uid = o.physician
  WHERE o.organization = $[organization]
  ORDER BY p.name, p.document ASC
`;

interface GetPhysiciansVars {
  organization: string;
}

interface Entity {
  uid: string;
  name: string;
  username: string;
  document: string;
  document_type: string;
}

type Props = {
  organization: string;
  loading?: boolean;
};

const RESULTS_PER_PAGE = 10;

export const PhysiciansTab: FC<Props> = (props) => {
  const [visible, setVisibile] = useState(false);

  const { rows, totalCount, loading, paginate, refetch } = useQueryPaginated<
    Entity,
    GetPhysiciansVars
  >(GET_PHYSICIANS, {
    skip: !props.organization,
    variables: {
      organization: props.organization,
    },
    pagination: {
      perPage: RESULTS_PER_PAGE,
    },
  });

  function onChange(pagination: TablePaginationConfig) {
    paginate({ page: pagination.current, perPage: pagination.pageSize });
  }

  return (
    <>
      <Table<Entity>
        rowKey="uid"
        loading={loading}
        onChange={onChange}
        footer={() => <Button onClick={() => setVisibile(true)}>New Account</Button>}
        dataSource={rows}
        pagination={{
          total: totalCount,
          pageSize: RESULTS_PER_PAGE,
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Username',
            dataIndex: 'username',
          },
          {
            title: 'Document',
            dataIndex: 'document',
          },
          {
            title: 'Document Type',
            dataIndex: 'document_type',
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            render: (it) => <LocalDate datetime={it} />,
          },
          {
            title: 'Actions',
            dataIndex: 'uid',
            render: (uid) => {
              return <Link to={`/people/${uid}`}>Edit</Link>;
            },
          },
        ]}
      />
      <CreatePhysicianModal
        visible={visible}
        organization={props.organization}
        onCancel={(created) => {
          setVisibile(false);
          created && refetch();
        }}
      />
    </>
  );
};
