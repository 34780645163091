import { useState, useCallback } from 'react';
import { parseURL, request } from './client';

type State = {
  loading: boolean;
};

type Config = {
  method?: 'post' | 'put' | 'delete' | 'patch' | 'get';
};

type UseMutation<Response, Payload> = State & {
  run: (payload: Payload, variables?: Record<string, any>) => Promise<Response>;
};

export function useMutation<TResponse, TPayload = undefined>(
  action: string,
  { method = 'post' }: Config = {}
): UseMutation<TResponse, TPayload> {
  const [state, setState] = useState<State>({ loading: false });

  const run = useCallback(
    async (payload: TPayload, variables?: Record<string, any>) => {
      try {
        setState({ loading: true });

        const path = `/${parseURL(action, variables)}`;

        const response = await request<TResponse>(path, {
          method: method.toUpperCase(),
          body: payload && method !== 'get' ? JSON.stringify(payload) : undefined,
        });

        return response;
      } finally {
        setState({ loading: false });
      }
    },
    []
  );

  return {
    ...state,
    run,
  };
}
