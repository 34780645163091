import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { client } from '~/dataSource/client';

export enum CognitoErrorCodes {
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
  LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException',
}

export type LoginCredentials = {
  username: string;
  password: string;
};

type UserProps = {
  permissions: string[];
  policies: {
    permissions: string[];
  }[];
};

class Policy {
  constructor(private permissions: string[]) {}

  can(perm: string) {
    return this.permissions.includes(perm);
  }
}

class User {
  private _username = '';
  private permissions: string[] = [];
  private policies: Policy[] = [];

  constructor(public isLoaded = false) {}

  update(props: UserProps & { username: string }) {
    this.isLoaded = true;

    this._username = props.username;
    this.permissions = props.permissions;
    this.policies = props.policies.map((it) => new Policy(it.permissions));
  }

  get username() {
    return this._username;
  }

  can(perm: string) {
    return (
      this.permissions.includes(perm) ||
      this.policies.some((policy) => policy.can(perm))
    );
  }

  cannot(perm: string) {
    return !this.can(perm);
  }
}

const temp: Record<string, any> = {};
const user: User = new User();

async function updateUserProps(session: CognitoUserSession) {
  const me = await client.get<{
    operator: UserProps;
  }>('/me');

  user.update({
    username: session.getIdToken().payload['cognito:username'],
    permissions: me.operator.permissions,
    policies: me.operator.policies,
  });

  document.dispatchEvent(
    new CustomEvent('user-props-updated', {
      detail: Object.freeze(user),
    })
  );
}

export function isLogged(): boolean {
  return user.isLoaded;
}

export function getCurrent(): Readonly<User> {
  return Object.freeze(user);
}

export async function getToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}

export async function login(): Promise<void> {
  await Auth.federatedSignIn({
    customProvider: 'google-workspace-quoretech',
  });
}

export async function logout(): Promise<void> {
  return Auth.signOut();
}

export async function completeNewPassword(password: string): Promise<void> {
  if (!temp.UserToComplete) {
    throw new Error('missing user');
  }

  return Auth.completeNewPassword(temp.UserToComplete, password);
}

export async function recover(): Promise<string> {
  const session = await Auth.currentSession();

  if (!session.isValid()) {
    throw new Error('invalid session');
  }

  await updateUserProps(session);

  console.info('User: recover success!');
  return session.getAccessToken().getJwtToken();
}

export async function confirmPasswordAgain(password: string): Promise<boolean> {
  const { username } = await Auth.currentUserInfo();
  return Auth.signIn(username, password)
    .then(() => true)
    .catch(() => false);
}

export async function getCurrentToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}
