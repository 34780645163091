import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Input, Table, TablePaginationConfig } from 'antd';
import { LocalDate } from '~/components/LocalDate';
import { PageHeader } from '~/components/PageHeader';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';
import { PatientsStatistics } from '../components/PatientsStatistics';

dayjs.extend(utc);

const GET_PATIENTS = `
  SELECT
    p.uid,
    p.name,
    p.sex,
    p.birthdate,
    p.email,
    p.document,
    p.document_type,
    p.created_at,
    p.updated_at
  FROM patients p
  $[[WHERE]]
  ORDER BY p.created_at DESC
`;

interface Entity {
  uid: string;
  name: string;
  email: string;
  birthdate: Date;
  sex: 'male' | 'female' | string;
  document: string;
  document_type: 'cpf' | string;
  created_at: Date;
  updated_at: Date;
}

const RESULTS_PER_PAGE = 20;

export const ListPatients: FC = () => {
  const { rows, totalCount, loading, pagination, paginate, refetch } =
    useQueryPaginated<Entity>(GET_PATIENTS, {
      pagination: {
        perPage: RESULTS_PER_PAGE,
      },
    });

  function onSearch(value: string) {
    const _value = value.trim().split(' ').join('%');
    const _where = `WHERE name ILIKE '%${_value}%' OR document ILIKE '%${_value}%'`;

    paginate({ page: 1 });
    refetch({ _where });
  }

  function onChange(pagination: TablePaginationConfig) {
    paginate({ page: pagination.current, perPage: pagination.pageSize });
  }

  return (
    <>
      <PageHeader title="Statistics">
        <PatientsStatistics />
      </PageHeader>

      <Divider />

      <PageHeader title="Patient List" />

      <div style={{ padding: '0 12px' }}>
        <Input.Search
          enterButton
          allowClear
          placeholder="Filter by Patient Name or Patient Document"
          onSearch={onSearch}
        />
        <br />
        <br />
        <Table<Entity>
          rowKey="uid"
          loading={loading}
          dataSource={rows}
          onChange={onChange}
          pagination={{
            showSizeChanger: true,
            total: totalCount,
            current: pagination.page,
            defaultPageSize: pagination.perPage,
          }}
          columns={[
            {
              width: 70,
              title: 'UID',
              dataIndex: 'uid',
              render: (uid: string) => <code>{uid.split('-')[0]}</code>,
            },
            { title: 'Name', dataIndex: 'name' },
            { title: 'Sex', dataIndex: 'sex' },
            {
              title: 'Birthdate',
              dataIndex: 'birthdate',
              render: (it) => dayjs.utc(it).format('DD/MM/YYYY'),
            },
            { title: 'Email', dataIndex: 'email' },
            { title: 'Document', dataIndex: 'document' },
            { title: 'Document Type', dataIndex: 'document_type' },
            {
              title: 'Created At',
              dataIndex: 'created_at',
              defaultSortOrder: 'descend',
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              title: 'Updated At',
              dataIndex: 'updated_at',
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              title: 'Action',
              dataIndex: 'uid',
              render: (uid) => {
                return <Link to={`/patients/${uid}`}>Edit</Link>;
              },
            },
          ]}
        />
      </div>
    </>
  );
};
