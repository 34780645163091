import { useCallback } from 'react';
import { useQuery } from '~/dataSource/useQuery';

const GET_LOCATIONS = `
  SELECT
    l.uid
  , concat(o.name, ' > ', l.name) as name
  FROM locations l
  JOIN organizations o ON o.uid = l.organization
  WHERE concat(o.name, ' ', l.name) ILIKE $[name]
  ORDER BY 2 ASC
`;

type GetLocationVars = {
  name: string;
};

type GetLocationResponse = {
  uid: string;
  name: string;
}[];

type Output = {
  loading: boolean;
  data?: GetLocationResponse;
  search(value: string, skip?: boolean): void;
};

export function useLocationSearch(): Output {
  const { data, loading, refetch } = useQuery<GetLocationResponse, GetLocationVars>(
    GET_LOCATIONS,
    { skip: true }
  );

  const search = useCallback(
    (value: string, skip = false) => {
      refetch({
        skip,
        variables: {
          name: `%${value.replaceAll(' ', '%')}%`,
        },
      });
    },
    [refetch]
  );

  return {
    data,
    loading,
    search,
  };
}
