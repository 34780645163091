import { Button, Card, Col, Row, Table } from 'antd';
import { PageHeader } from '~/components/PageHeader';
import { useQuery } from '~/dataSource/useQuery';

const GET_PEOPLE_WORKLOAD = `
  WITH exam_count_todo AS (
    SELECT
      COUNT(zt.*)::int "count"
    , (zt.owners->>'__owner_field__')::uuid "operator_uid"
    FROM zoho_tasks zt
    WHERE zt.metadata->>'status' = '__todo_status__' AND zt.metadata->>'exam_type' NOT IN (__service_type__)
    GROUP BY 2
  ), exam_count_up AS (
    SELECT
      COUNT(zt.*)::int "count"
    , (zt.owners->>'__owner_field__')::uuid "operator_uid"
    FROM zoho_tasks zt
    WHERE zt.metadata->>'status' = '__up_status__' AND zt.metadata->>'exam_type' NOT IN (__service_type__)
    GROUP BY 2
  )
  SELECT
    obv.person->>'name' "name"
  , obv.uid
  , ec.count "todoCount"
  , ecu.count "upCount"
  FROM exam_count_todo ec
  LEFT JOIN exam_count_up ecu ON ecu.operator_uid = ec.operator_uid
  LEFT JOIN operators_basic_view obv ON obv.uid = ec.operator_uid
  ORDER BY 1
`;

type Entity = {
  uid: string;
  name: string;
  todoCount: number;
  upCount: number;
};

function toQuery(vars: Record<string, string>) {
  return GET_PEOPLE_WORKLOAD.replaceAll('__owner_field__', vars.ownerField)
    .replace('__todo_status__', vars.todoStatus)
    .replace('__up_status__', vars.upStatus)
    .replaceAll('__service_type__', vars.serviceTypes);
}

export function Workload() {
  const technicias = useQuery<Entity[]>(
    toQuery({
      ownerField: 'report_reviewer',
      todoStatus: 'ready_to_report',
      upStatus: 'ready_to_report',
      serviceTypes: `'taas', 'essential'`,
    })
  );

  const physicians = useQuery<Entity[]>(
    toQuery({
      ownerField: 'report_signer',
      todoStatus: 'ready_to_sign',
      upStatus: 'ready_to_report',
      serviceTypes: `'taas', 'essential', 'standard'`,
    })
  );

  return (
    <>
      <PageHeader
        title="Personnel Workload"
        extra={
          <Button
            loading={technicias.loading || physicians.loading}
            onClick={() => {
              Promise.all([physicians.refetch(), technicias.refetch()]);
            }}
            size="small"
          >
            Reload
          </Button>
        }
      />
      <br />
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={12}>
          <Card title="Technicians" bodyStyle={{ padding: 0 }}>
            <Table<Entity>
              rowKey="uid"
              dataSource={technicias.data}
              loading={technicias.loading}
              pagination={false}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name',
                  render: (value) => value || <strong>No User Selected</strong>,
                },
                {
                  title: 'Todo Count',
                  dataIndex: 'todoCount',
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card title="Physicians" bodyStyle={{ padding: 0 }}>
            <Table<Entity>
              rowKey="uid"
              dataSource={physicians.data}
              loading={physicians.loading}
              pagination={false}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name',
                  render: (value) => value || <strong>No User Selected</strong>,
                },
                {
                  title: 'Upcoming Count',
                  dataIndex: 'upCount',
                },
                {
                  title: 'Todo Count',
                  dataIndex: 'todoCount',
                },
                {
                  title: 'Total Count',
                  key: 'total_count',
                  render: (row) => row.upCount + row.todoCount,
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
