import React, { useEffect } from 'react';
import { message } from 'antd';
import { useMutation } from '~/dataSource/useMutation';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { BaseForm, Form } from '~/components/BaseForm';
import { toCamelCase } from '~/helpers/convertCase';

const GET_PREFERENCES = `
  SELECT
    $[[SEGMENT]]_preferences "preferences"
  FROM organizations
  WHERE uid = $[uid]
`;

type Entity = {
  preferences: Record<string, unknown>;
};

type Props = {
  segment: 'report' | 'contact' | 'processing' | 'version';
  organization: string;
  children: React.ReactNode;
};

export const PreferencesForm = ({ segment, organization, children }: Props) => {
  const { data, loading } = useQueryEntity<Entity, any>(GET_PREFERENCES, {
    variables: { uid: organization, _segment: segment },
    skip: !organization,
  });

  const mutation = useMutation(
    `/organizations/${organization}/preferences/${segment}`,
    {
      method: 'put',
    }
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(toCamelCase(data.preferences));
    }
  }, [data]);

  async function onFinish(newData: any) {
    try {
      await mutation.run(newData);
      const title = segment.replace(/^(\w)/, (v) => v.toUpperCase());
      message.success(`${title} Preferences updated with success!`);
    } catch (err) {
      message.error('Ops, Something went wrong!');
      console.error(err);
    }
  }

  return (
    <BaseForm
      form={form}
      onFinish={onFinish}
      disabled={loading || mutation.loading}
      loading={mutation.loading}
    >
      {children}
    </BaseForm>
  );
};
