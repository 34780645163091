import React, { useState } from 'react';
import { Descriptions, message, Select, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useLocationSearch } from '~/hooks/useLocationSearchHook';
import { Form, FormItem } from '~/components/BaseForm';
import { EditCard } from '~/components/EditCard';
import { useMutation } from '~/dataSource/useMutation';

type UpdateLocationInput = {
  location: string;
};

type Props = {
  exam: string;
  location?: {
    uid: string;
    name: string;
  };
  organization?: {
    uid: string;
    name: string;
  };

  onSuccess: () => void;
};

export const ExamLocationCard: React.FC<Props> = ({
  exam,
  location,
  organization,
  onSuccess,
}) => {
  const searcher = useLocationSearch();
  const [form] = Form.useForm<UpdateLocationInput>();
  const [isEditing, setEditing] = useState(false);

  const { run: updateLocation, loading: runLoading } = useMutation<
    undefined,
    UpdateLocationInput
  >(`/exams/${exam}/location`, {
    method: 'put',
  });

  function onFinish(values: UpdateLocationInput) {
    updateLocation(values)
      .then(() => {
        onSuccess();
        message.success('Location updated with success');
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong!');
      })
      .finally(() => setEditing(false));
  }

  return (
    <EditCard
      scope="admin:exams:location:update"
      isEditing={isEditing}
      onEditingChange={setEditing}
      cardProps={{
        title: 'Location',
      }}
      actionProps={{
        loading: runLoading,
        onSave: () => form.submit(),
      }}
      editChildren={
        <Form form={form} onFinish={onFinish}>
          <FormItem name="location" style={{ marginBottom: -2, marginTop: -1 }}>
            <Select
              size="small"
              showSearch
              defaultValue={''}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => searcher.search(value, !value)}
              notFoundContent={
                searcher.loading ? <Spin size="small" /> : 'Nothing found'
              }
              options={searcher.data?.map(({ uid: value, name: label }) => ({
                label,
                value,
              }))}
            />
          </FormItem>
        </Form>
      }
    >
      <Descriptions
        column={{
          lg: 2,
          xs: 1,
        }}
      >
        <Descriptions.Item label="Organization">
          {organization && (
            <Link to={`/organizations/${organization.uid}`}>{organization?.name}</Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Location">
          {location && <Link to={`/locations/${location?.uid}`}>{location?.name}</Link>}
        </Descriptions.Item>
      </Descriptions>
    </EditCard>
  );
};
