import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Input } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { LocalDate } from '~/components/LocalDate';
import { PageHeader } from '~/components/PageHeader';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';

const GET_PHYSICIANS = `
  SELECT
    u.uid
  , u.username
  , row_to_json(p.*) "person"
  FROM users u
  JOIN physicians p ON p.uid = u.person
  $[[WHERE]]
  ORDER BY p.name, p.document ASC
`;

interface Entity {
  uid: string;
  username: string;
  person: {
    name: string;
    document: string;
    document_type: string;
    created_at: string;
    updated_at: string;
  };
}

export const ListPhysicians: FC = () => {
  const { rows, totalCount, loading, paginate, refetch, pagination } =
    useQueryPaginated<Entity>(GET_PHYSICIANS, {
      pagination: {
        perPage: 20,
      },
    });

  function onSearch(value: string) {
    const _value = value.trim().split(' ').join('%');
    const _where = _value
      ? `WHERE p.name ILIKE '%${_value}%' OR p.document ILIKE '%${_value}%' OR username ILIKE '%${_value}%'`
      : '';
    paginate({ page: 1 });
    refetch({ _where });
  }

  function onChange(pagination: TablePaginationConfig) {
    paginate({ page: pagination.current, perPage: pagination.pageSize });
  }

  return (
    <>
      <PageHeader title="Personnel List" />

      <Divider />

      <div style={{ padding: '0 12px' }}>
        <Input.Search
          enterButton
          allowClear
          placeholder="Filter by Name, Username or Document Number"
          onSearch={onSearch}
        />

        <br />
        <br />

        <Table<Entity>
          rowKey="uid"
          loading={loading}
          dataSource={rows}
          onChange={onChange}
          footer={() => (
            <>
              Total: <strong>{totalCount}</strong>
            </>
          )}
          pagination={{
            showSizeChanger: true,
            total: totalCount,
            current: pagination.page,
            defaultPageSize: pagination.perPage,
          }}
          columns={[
            {
              title: 'Name',
              dataIndex: ['person', 'name'],
            },
            {
              title: 'Username',
              dataIndex: ['username'],
            },
            {
              title: 'Document',
              dataIndex: ['person', 'document'],
            },
            {
              title: 'Document Type',
              dataIndex: ['person', 'document_type'],
            },
            {
              title: 'Created At',
              dataIndex: ['person', 'created_at'],
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              title: 'Updated At',
              dataIndex: ['person', 'updated_at'],
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              title: 'Action',
              dataIndex: ['person', 'uid'],
              render: (uid) => {
                return <Link to={`/people/${uid}`}>Edit</Link>;
              },
            },
          ]}
        />
      </div>
    </>
  );
};
