import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card as AntCard, Col, Row, Statistic } from 'antd';
import { useQuery } from '~/dataSource/useQuery';

const GET_TOTALS = `
  SELECT COUNT(*), 'exams' t FROM  exams
  UNION
  SELECT COUNT(*), 'people' t FROM physicians
  UNION
  SELECT COUNT(*), 'patients' t FROM patients
`;

type GetTotalsResponse = { t: string; count: number }[];

const Card: FC<any> = ({ title, loading, value, href }) => {
  return (
    <AntCard>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Statistic loading={loading} title={title} value={value} />
        <Link to={href}>List</Link>
      </div>
    </AntCard>
  );
};

export const Counters: FC = () => {
  const { data, loading } = useQuery<GetTotalsResponse>(GET_TOTALS);

  return (
    <Row gutter={12}>
      <Col span={8}>
        <Card
          title="Total Exams"
          value={data?.find((e) => e.t === 'exams')?.count}
          loading={loading}
          href="/exams"
        />
      </Col>
      <Col span={8}>
        <Card
          title="Total Patients"
          value={data?.find((e) => e.t === 'patients')?.count}
          loading={loading}
          href="/patients"
        />
      </Col>
      <Col span={8}>
        <Card
          title="Total Personnel"
          value={data?.find((e) => e.t === 'people')?.count}
          loading={loading}
          href="/people"
        />
      </Col>
    </Row>
  );
};
