import dayjs from 'dayjs';
import { useMemo } from 'react';
import {
  DomainTuple,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory';
import { Alert } from 'antd';
import { useQuery } from '~/dataSource/useQuery';
import { useElementWidth } from '~/hooks/useElementWidthHook';

const GET_DEVICE_BATTERY_HISTORY = `
  SELECT
    e.uid "exam"
  , e.status
  , es.created_at "x"
  , (bl.information->>'drainage_per_hour')::float "y"
  FROM exams e
  JOIN exams_status es ON e.uid = es.exam AND es.status = 'annotations_created'
  LEFT JOIN metrics.battery_levels bl ON e.uid = bl.exam
  WHERE device = $[uid]
  ORDER BY 3 DESC
  LIMIT 10
`;

type Entity = {
  status: string;
  exam: string;
  y: number;
  x: string;
};

type Props = {
  device: string;
};

export function DeviceBatteryHistory({ device }: Props) {
  const { data, loading } = useQuery<Entity[]>(GET_DEVICE_BATTERY_HISTORY, {
    variables: { uid: device },
    skip: !device,
  });

  const { container, width } = useElementWidth();

  const domain: DomainTuple = useMemo(() => {
    if (!data) {
      return [0, 1];
    }
    const maxDomain = Math.max(...data.map((it) => it.y));

    return [0, Math.max(1, maxDomain)];
  }, [data]);

  const sortedPoints = useMemo(
    () => data?.toSorted((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()),
    [data]
  );

  return (
    <div ref={container}>
      <Alert
        showIcon
        type="info"
        description="A healthy QuoreOne will have an hourly drainage of 0.55%, indicated by the color
        green. An unhealthy QuoreOne, with an hourly drainage of 0.60%, will not complete
        the 7-day test duration, shown in red. A drainage rate between these two values
        will be considered a warning, marked in orange."
      />
      <VictoryChart
        width={width}
        height={400}
        padding={{
          top: 20,
          left: 80,
          right: 30,
          bottom: 100,
        }}
        domainPadding={{ y: 10, x: 20 }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        {!loading && (
          <VictoryLine data={sortedPoints} style={{ data: { stroke: '#ececec' } }} />
        )}

        {!loading && (
          <VictoryScatter
            data={sortedPoints}
            labels={({ datum }: { datum: Entity }) => datum.y?.toFixed(3) || 0}
            style={{
              data: {
                fill: ({ datum }) =>
                  datum.y >= 0.6 ? 'red' : datum.y >= 0.55 ? 'orange' : 'green',
              },
            }}
          />
        )}

        <VictoryAxis
          label={'Exam Processing Date'}
          axisLabelComponent={<VictoryLabel dy={54} />}
          style={{
            tickLabels: {
              angle: -45,
              verticalAnchor: 'middle',
              textAnchor: 'end',
            },
          }}
          tickFormat={(x: string) => dayjs(x).format('DD/MM/YYYY')}
        />
        <VictoryAxis
          label={'Hourly Drainage'}
          axisLabelComponent={<VictoryLabel dy={-20} />}
          dependentAxis
          domain={domain}
        />
      </VictoryChart>
    </div>
  );
}
