import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Descriptions, Row, Col, Table } from 'antd';
import { ExamCard } from './ExamCard';
import { LocalDate } from '~/components/LocalDate';
import { ServiceType } from '~/components/ServiceType';
import { useQueryEntity } from '~/dataSource/useQueryEntity';

const GET_INVOICE = `
  SELECT
    oi.uid
  , oi.created_at
  , oi.external_id
  , oi.items_summary
  , oi.metadata
  , row_to_json(o.*) as organization
  FROM organizations_invoices oi
  JOIN organizations o ON oi.organization = o.uid
  WHERE oi.uid = $[uid]
`;

interface GetInvoiceVars {
  uid: string;
}

interface Entity {
  uid: string;
  created_at: string;
  external_id: string;
  items_summary: any;
  metadata: {
    invoice_number: string;
    billing_period: {
      start_date: string;
      end_date: string;
    };
  };
  organization: {
    uid: string;
    name: string;
  };
}

type Props = { uid: string };

export const InvoiceDetails: React.FC<Props> = ({ uid }) => {
  const { data, loading } = useQueryEntity<Entity, GetInvoiceVars>(GET_INVOICE, {
    variables: { uid },
    skip: !uid,
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title="Invoice">
          <Descriptions>
            <Descriptions.Item label="Organization">
              <Link to={`/organizations/${data?.organization?.uid}`}>
                {data?.organization?.name}
              </Link>
            </Descriptions.Item>

            <Descriptions.Item label="External Id">
              {data?.external_id}
            </Descriptions.Item>

            <Descriptions.Item label="Invoice Number">
              {data?.metadata.invoice_number}
            </Descriptions.Item>

            <Descriptions.Item label="Created At">
              <LocalDate datetime={data?.created_at} />
            </Descriptions.Item>

            <Descriptions.Item label="Billing Period Start">
              <LocalDate datetime={data?.metadata.billing_period.start_date} />
            </Descriptions.Item>

            <Descriptions.Item label="Billing Period End">
              <LocalDate datetime={data?.metadata.billing_period.end_date} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>

      <Col span={24}>
        <Card title="Items Summary">
          <Table
            pagination={false}
            loading={loading}
            dataSource={data?.items_summary.items}
            columns={[
              {
                title: 'Service Type',
                dataIndex: 'code',
                render: (code: string) => (
                  <ServiceType>{code.replace('exams_', '')}</ServiceType>
                ),
              },
              {
                title: 'Quantity',
                dataIndex: 'quantity',
              },
            ]}
          />
        </Card>
      </Col>

      <Col span={24}>{data?.uid && <ExamCard invoice={data?.uid}></ExamCard>}</Col>
    </Row>
  );
};
