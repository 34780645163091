import { FC } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Card, Space, Tabs } from 'antd';
import { PageHeader } from '~/components/PageHeader';
import { ServiceType } from '~/components/ServiceType';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { ZohoTab } from '../components/ZohoTab';
import { ExamsTab } from '../components/ExamsTab';
import { DevicesTab } from '../components/DevicesTab';
// import { InvoicesTab } from '../components/InvoicesTab';
import { LocationsTab } from '../components/LocationsTab';
import { PhysiciansTab } from '../components/PhysiciansTab';
import { OrganizationPreferences } from '../Preferences/OrganizationPreferences';

const GET_ORGANIZATION = `
  SELECT
    uid
  , name
  , legal_name
  , account_name
  , status
  , oc.external_id
  , oc.service_type
  FROM organizations o
  LEFT JOIN organizations_contracts oc ON oc.organization = o.uid
  WHERE o.uid = $[uid]
`;

interface GetOrganizationVars {
  uid: string;
}

interface Entity {
  uid: string;
  name: string;
  legal_name: string;
  account_name: string;
  status: string;
  external_id?: string;
  service_type?: string;
}

export const ViewOrganization: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  if (!uid) return null;

  const { data, loading } = useQueryEntity<Entity, GetOrganizationVars>(
    GET_ORGANIZATION,
    { variables: { uid }, skip: !uid }
  );

  return (
    <Card>
      <PageHeader
        onBack={() => navigate(-1)}
        title={`Organization: ${data?.name || ''}`}
        extra={
          <Space>
            <ServiceType>{data?.service_type}</ServiceType>
            <Link to="edit">Edit</Link>
          </Space>
        }
      />

      <Tabs
        type="card"
        items={[
          {
            key: 'exams',
            label: 'Exams',
            children: <ExamsTab organization={uid} />,
          },
          {
            key: 'devices',
            label: 'Devices',
            children: <DevicesTab organization={uid} />,
          },
          {
            key: 'personnel',
            label: 'Personnel',
            children: <PhysiciansTab organization={uid} loading={loading} />,
          },
          {
            key: 'locations',
            label: 'Locations',
            children: <LocationsTab organization={uid} loading={loading} />,
          },
          // {
          //   key: 'invoices',
          //   label: 'Invoices',
          //   children: <InvoicesTab organization={uid} loading={loading} />,
          // },
          {
            key: 'preferences',
            label: 'Preferences',
            children: <OrganizationPreferences organization={uid} loading={loading} />,
          },
          {
            key: 'zoho',
            label: 'Zoho Sync',
            children: <ZohoTab organization={uid} loading={loading} />,
          },
        ]}
      />
    </Card>
  );
};
