import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Divider, Form, message } from 'antd';
import { PageHeader } from '~/components/PageHeader';
import { useMutation } from '~/dataSource/useMutation';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { PatientForm } from '../components/PatientForm';

dayjs.extend(utc);

const GET_PATIENT = `
  SELECT
    uid
  , name
  , email
  , sex
  , birthdate
  , document
  FROM patients
  WHERE uid = $[uid]
`;

interface Entity {
  uid: string;
  name: string;
  sex: string;
  email: string;
  document: string;
  birthdate: dayjs.Dayjs;
}

interface GetPatientArgs {
  uid?: string;
}

type UpdatePatientInput = Omit<Entity, 'uid' | 'birthdate'> & {
  birthdate: string;
};

export const EditPatient: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  const { data } = useQueryEntity<Entity, GetPatientArgs>(GET_PATIENT, {
    variables: { uid },
    skip: !uid,
  });

  const [form] = Form.useForm<Entity>();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        birthdate: dayjs.utc(data.birthdate),
      });
    }
  }, [data]);

  const { run: updatePatient, loading } = useMutation<undefined, UpdatePatientInput>(
    `patients/${uid}`,
    { method: 'put' }
  );

  async function onFinish(input: UpdatePatientInput) {
    try {
      await updatePatient(input);
      message.success('Patient updated with success');
      navigate(-1);
    } catch (err) {
      console.error(err);
      message.error('Something went wrong!');
    }
  }

  return (
    <Card>
      <PageHeader title={`Patient: ${data?.name || ''}`} onBack={() => navigate(-1)} />

      <Divider />

      <PatientForm loading={loading} form={form} onFinish={onFinish} />
    </Card>
  );
};
