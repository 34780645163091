import React from 'react';
import { ConfigProvider } from 'antd';

export const Theme = ({ children }: React.PropsWithChildren) => (
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          sizeStep: 3,
          borderRadius: 0,
          colorPrimary: '#b22330',
          colorLink: '#b22330',
        },
        components: {
          Layout: {
            colorBgHeader: '#1d2933',
            colorBgTrigger: '#273744',
          },
          Menu: {
            colorItemBg: '#273744',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  </React.StrictMode>
);
