import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Protected } from '~/components/Protected';
import { EditPatient } from './routes/EditPatient';
import { ListPatients } from './routes/ListPatients';

export const PatientsRoutes: FC = () => {
  return (
    <Protected scope="admin:patients:list">
      <Routes>
        <Route path="/" element={<ListPatients />} />
        <Route path=":uid" element={<EditPatient />} />
      </Routes>
    </Protected>
  );
};
