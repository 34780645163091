/* eslint-disable @typescript-eslint/ban-types */
export const _toCamelCase = (input: string): string =>
  input.replace(/_[a-z]/g, (letter: string) => letter.replace('_', '').toUpperCase());

export const toCamelCase = (it?: {}): any => {
  if (it == undefined) {
    return it;
  }

  if (['string', 'number'].includes(typeof it)) {
    return it;
  }

  return Object.fromEntries(
    Object.entries(it).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [_toCamelCase(key), value.map((v) => toCamelCase(v))];
      }

      if (String(value) === '[object Object]') {
        return [_toCamelCase(key), toCamelCase(value as any)];
      }

      return [_toCamelCase(key), value];
    })
  );
};
