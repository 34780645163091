import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Space, Divider } from 'antd';
import { LocalDate } from '~/components/LocalDate';
import { PageHeader } from '~/components/PageHeader';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';
import { generateHostLink } from '~/helpers/datadog';

const GET_DOCKING_STATIONS = `
  SELECT
    d.uid,
    d.serial_number,
    d.model,
    d.software_version,
    l.organization "organization_uid",
    o.name "organization_name",
    d.created_at,
    d.updated_at
  FROM docking_stations d
  LEFT JOIN locations l ON l.uid = d.location
  LEFT JOIN organizations o on o.uid = l.organization
  $[[WHERE]]
  ORDER BY d.created_at DESC
`;

interface Entity {
  uid: string;
  serial_number: string;
  model: string;
  software_version: string;
  organization_name: string;
  organization_uid: string;
  created_at: Date;
  updated_at: Date;
}

const RESULTS_PER_PAGE = 20;

export const ListDockingStations: FC = () => {
  const { rows, totalCount, loading, paginate, refetch } = useQueryPaginated<Entity>(
    GET_DOCKING_STATIONS,
    {
      pagination: {
        perPage: RESULTS_PER_PAGE,
      },
    }
  );

  function onSearch(value: string) {
    const _value = value.startsWith('sn') ? value.slice(2) : value;
    const _where = value ? `WHERE d.serial_number ILIKE '%${_value}%'` : '';
    refetch({ _where });
  }

  return (
    <>
      <PageHeader title="Docking Station List" />

      <div style={{ padding: '0 12px' }}>
        <Input.Search
          enterButton
          allowClear
          placeholder="Filter by SN"
          onSearch={onSearch}
        />
        <br />
        <br />
        <Table<Entity>
          rowKey="uid"
          loading={loading}
          dataSource={rows}
          onChange={(pagination) => {
            paginate({ page: pagination.current, perPage: pagination.pageSize });
          }}
          pagination={{
            total: totalCount,
            showSizeChanger: true,
            defaultPageSize: RESULTS_PER_PAGE,
          }}
          columns={[
            {
              width: 70,
              title: 'UID',
              dataIndex: 'uid',
              render: (uid: string) => <code>{uid.split('-')[0]}</code>,
            },
            {
              title: 'Serial Number',
              dataIndex: 'serial_number',
            },
            { title: 'Model', dataIndex: 'model' },
            { title: 'Software Version', dataIndex: 'software_version' },
            {
              title: 'Organization',
              dataIndex: 'organization_uid',
              render: (organization, row) => (
                <Link to={`/organizations/${organization}`}>
                  {row.organization_name}
                </Link>
              ),
            },
            {
              title: 'Created At',
              dataIndex: 'created_at',
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              title: 'Updated At',
              dataIndex: 'updated_at',
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              title: 'Action',
              dataIndex: 'uid',
              width: 160,
              render: (uid, row) => {
                return (
                  <Space>
                    <Link to={`${uid}`}>Details</Link>
                    <Divider type="vertical" />
                    <a
                      href={generateHostLink([row.serial_number])}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Logs
                    </a>
                  </Space>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};
