const HOUR = 60;
const DAY = 24 * HOUR;

const pluralize = (n: number, s: string) => `${n} ${s}${n === 1 ? '' : 's'}`;

/**
 * @param duration in minutes
 */
export function durationHuman(duration: number): string {
  if (duration < HOUR) {
    return `${duration} minutes`;
  }

  if (duration < DAY) {
    return pluralize(Math.round(duration / HOUR), 'hour');
  }

  return pluralize(Math.round(duration / DAY), 'day');
}

export function durationFormated(duration: number): string {
  return new Duration(duration).toString();
}

export class Duration {
  constructor(public millis: number) {}

  private static SECOND = 1e3;
  private static MINUTE = 60 * Duration.SECOND;
  private static HOUR = 60 * Duration.MINUTE;
  private static DAY = 24 * Duration.HOUR;

  static fromHours(hours: number) {
    return new Duration(hours * Duration.HOUR);
  }

  static fromSeconds(seconds: number) {
    return new Duration(seconds * Duration.SECOND);
  }

  toParts() {
    const days = Math.floor(this.millis / Duration.DAY);
    const hours = Math.floor((this.millis % Duration.DAY) / Duration.HOUR);
    const minutes = Math.floor((this.millis % Duration.HOUR) / Duration.MINUTE);
    const seconds = Math.floor((this.millis % Duration.MINUTE) / Duration.SECOND);
    const milliseconds = Math.floor(this.millis % 1e3);

    return {
      days,
      hours,
      minutes,
      seconds,
      milliseconds,
    };
  }

  toString(millis = true) {
    const parts = this.toParts();
    const str = (n: number, p = 2) => n.toString().padStart(p, '0');

    const days =
      parts.days > 1 ? `${parts.days} days ` : parts.days === 1 ? `1 day ` : '';

    let ms = '';
    if (millis) {
      ms = `.${str(parts.milliseconds, 3)}`;
    }

    return `${days}${str(parts.hours)}:${str(parts.minutes)}:${str(
      parts.seconds
    )}${ms}`;
  }
}

(window as any).Duration = Duration;
