import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { durationHuman } from '~/helpers/duration';
import { LocalDate } from '~/components/LocalDate';
import { ExamStatus } from '~/components/ExamStatus';
import { ServiceType } from '~/components/ServiceType';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';

const GET_EXAMS = `
  SELECT
    uid
  , mode
  , status
  , patient
  , device
  , created_at
  , service_type
  , options
  FROM exams_view
  WHERE organization->>'uid' = $[uid]
  ORDER BY created_at DESC
`;

interface Entity {
  uid: string;
  status: string;
  created_at: Date;
  service_type: string;
  options: {
    duration: number;
  };
  patient: {
    uid: string;
    name: string;
    document: string;
    document_type: string;
  };
  device?: {
    uid: string;
    serial_number: string;
  };
}

type Props = {
  organization: string;
};

export const ExamsTab: React.FC<Props> = ({ organization }) => {
  const { loading, rows, totalCount, paginate } = useQueryPaginated<Entity>(GET_EXAMS, {
    skip: !organization,
    pagination: { perPage: 20 },
    variables: { uid: organization },
  });

  return (
    <Table<Entity>
      footer={() => (
        <>
          Total: <strong>{totalCount}</strong>
        </>
      )}
      rowKey="uid"
      loading={loading}
      dataSource={rows}
      onChange={(pagination) => {
        paginate({
          page: pagination.current,
          perPage: pagination.pageSize,
        });
      }}
      pagination={{
        showSizeChanger: true,
        total: totalCount,
        defaultPageSize: 20,
      }}
      columns={[
        {
          width: 100,
          title: 'UID',
          dataIndex: 'uid',
          render: (uid: string) => (
            <Link to={`/exams/${uid}`}>
              <span style={{ fontFamily: 'monospace' }}>{uid.split('-')[0]}</span>
            </Link>
          ),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (status: string) => <ExamStatus status={status} />,
        },
        {
          title: 'Patient Name',
          dataIndex: 'patient',
          render: (patient: Entity['patient']) => (
            <Link to={`/patients/${patient.uid}`}>{patient.name}</Link>
          ),
        },
        {
          title: 'Document',
          dataIndex: ['patient', 'document'],
        },
        {
          title: 'Service Type',
          dataIndex: 'service_type',
          render: (serviceType: string) => <ServiceType children={serviceType} />,
        },
        {
          title: 'Duration',
          dataIndex: 'options',
          render: (options: Entity['options']) => durationHuman(options.duration),
        },
        {
          title: 'Device',
          dataIndex: 'device',
          render: (device: Entity['device']) =>
            device ? (
              <Link to={`/devices/quoreones/${device.uid}`}>
                {device.serial_number}
              </Link>
            ) : (
              '-'
            ),
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          width: 180,
          render: (it) => <LocalDate datetime={it} />,
        },
      ]}
    />
  );
};
