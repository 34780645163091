import { FC } from 'react';
import { Input } from 'antd';
import { BaseForm, BaseFormProps, FormItem } from '~/components/BaseForm';

export const LocationForm: FC<BaseFormProps> = (props) => {
  return (
    <BaseForm {...props}>
      <FormItem
        label="Name"
        name="name"
        rules={[{ required: true, message: 'please input location name' }]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="Address"
        name="address"
        rules={[{ required: true, message: 'please input location address' }]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="City"
        name="city"
        rules={[{ required: true, message: 'please input location city' }]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="State"
        name="state"
        rules={[{ required: true, message: 'please input location state' }]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="Postal code"
        name="postalCode"
        rules={[{ required: true, message: 'please input location postal code' }]}
      >
        <Input />
      </FormItem>
    </BaseForm>
  );
};
