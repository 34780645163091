import { FC, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, Descriptions, Divider, Form, Space, Tabs, message } from 'antd';
import { generateTicketLink } from '~/helpers/desk';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { useMutation } from '~/dataSource/useMutation';
import { PageHeader } from '~/components/PageHeader';
import { DeviceForm } from '../components/DeviceForm';
import { DeviceBatteryHistory } from '../components/DeviceBatteryHistory';
import { DeviceLastExamsTable } from '../components/DeviceLastExamsTable';

const GET_DEVICE = `
  SELECT
    d.uid
  , d.serial_number
  , d.firmware_version
  , d.mac_address
  , zd.ticket_id "ticket_id"
  , json_build_object(
    'uid', l.uid,
    'name', l.name
  ) "location"
  , json_build_object(
    'uid', o.uid,
    'name', o.name
  ) "organization"
  FROM devices d
  LEFT JOIN locations l ON l.uid = d.location
  LEFT JOIN organizations o ON o.uid = l.organization
  LEFT JOIN zoho_devices zd ON zd.device = d.uid
  WHERE d.uid = $[uid]
`;

interface FormValues {
  mac_address: string;
  serial_number: string;
  firmware_version: string;
  location: {
    key: string;
    label: string;
    value: string;
  };
}

interface Entity {
  uid: string;
  serial_number: string;
  firmware_version: string;
  ticket_id: string;
  location: {
    uid: string;
    name: string;
  };
  organization: {
    uid: string;
    name: string;
  };
}

interface GetDeviceArgs {
  uid?: string;
}

interface UpdateDeviceInput {
  serialNumber: string;
  firmwareVersion: string;
  location: string;
}

export const EditDevice: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  const { data } = useQueryEntity<Entity, GetDeviceArgs>(GET_DEVICE, {
    variables: { uid },
    skip: !uid,
  });

  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        location: {
          key: data.location.uid,
          label: data.location.name,
          value: data.location.uid,
        },
      });
    }
  }, [data]);

  const { run: updateDevice, loading } = useMutation<undefined, UpdateDeviceInput>(
    `devices/${uid}`,
    { method: 'put' }
  );

  function onFinish(formValues: FormValues) {
    updateDevice({
      firmwareVersion: formValues.firmware_version,
      serialNumber: formValues.serial_number,
      location: formValues.location.value,
    })
      .then(() => {
        message.success('Device updated with success');
        navigate(-1);
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }

  if (!uid) return null;

  return (
    <Space direction="vertical">
      <Card>
        <PageHeader
          title={`Device: ${data?.serial_number || uid}`}
          onBack={() => navigate(-1)}
        />
        <Divider />

        <DeviceForm loading={loading} form={form} onFinish={onFinish} />
      </Card>
      <Card title="Connections">
        <Descriptions>
          <Descriptions.Item label="Location">
            <Space>
              <Link to={`/organizations/${data?.organization?.uid}`}>
                {data?.organization?.name}
              </Link>
              <span>&gt;</span>
              <Link to={`/locations/${data?.location?.uid}`}>
                {data?.location?.name}
              </Link>
            </Space>
          </Descriptions.Item>

          <Descriptions.Item label="Zoho Desk">
            {data?.ticket_id ? (
              <a
                key="openZohoDeskLink"
                href={generateTicketLink(data?.ticket_id)}
                target="_blank"
                rel="noopener noparent"
              >
                {data?.ticket_id}
              </a>
            ) : (
              '-'
            )}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title="History">
        <Tabs
          items={[
            {
              key: 'exams',
              label: 'Exams',
              children: <DeviceLastExamsTable device={uid} />,
            },
            {
              key: 'battery',
              label: 'Battery',
              children: <DeviceBatteryHistory device={uid} />,
            },
          ]}
        />
      </Card>
    </Space>
  );
};
