import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Divider, Form, message } from 'antd';
import { useQuery } from '~/dataSource/useQuery';
import { PageHeader } from '~/components/PageHeader';
import { useMutation } from '~/dataSource/useMutation';
import { OrganizationForm } from '../components/OrganizationForm';

const GET_ORGANIZATION = `
  SELECT
    o.name
  , o.legal_name "legalName"
  , o.account_name "accountName"
  , o.status
  , o.document
  , o.parent_uid parent
  , c.service_type "serviceType"
  FROM organizations o
  LEFT JOIN organizations_contracts c ON o.uid = c.organization
  WHERE o.uid = $[uid]
`;

interface UpdateOrganizationInput {
  name: string;
  status: string;
  document: string;
  parent: string;
  serviceType: string;
}

interface GetOrganizationVars {
  uid?: string;
}

type GetOrganizationResponse = UpdateOrganizationInput[];

type UpdateOrganizationResponse = UpdateOrganizationInput[];

export const EditOrganization: FC = () => {
  const navigate = useNavigate();
  const { uid } = useParams<{ uid: string }>();

  const { data } = useQuery<GetOrganizationResponse, GetOrganizationVars>(
    GET_ORGANIZATION,
    { variables: { uid }, skip: !uid }
  );

  const [form] = Form.useForm<UpdateOrganizationInput>();

  const { run: updateOrganization, loading } = useMutation<
    UpdateOrganizationResponse,
    UpdateOrganizationInput
  >(`organizations/${uid}`, { method: 'patch' });

  function onFinish(input: UpdateOrganizationInput) {
    updateOrganization(input)
      .then(() => {
        message.success('Organization updated with success!');
      })
      .catch(() => {
        message.error('Something went wrong!');
      });
  }

  useEffect(() => {
    if (data?.length) {
      form.setFieldsValue(data[0]);
    }
  }, [data]);

  return (
    <Card>
      <PageHeader title="Edit Organization" onBack={() => navigate(-1)} />

      <Divider />

      <OrganizationForm form={form} onFinish={onFinish} loading={loading} />
    </Card>
  );
};
