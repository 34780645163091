import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Divider,
  Drawer,
  Select,
  Space,
  Statistic,
  Transfer,
  message,
} from 'antd';
import { useFetch } from '~/dataSource/useFetch';
import { useQuery } from '~/dataSource/useQuery';
import { useMutation } from '~/dataSource/useMutation';
import { useQueryEntity } from '~/dataSource/useQueryEntity';

const GET_OPERATOR = `
  SELECT
    o.permissions
  , o.policies
  , (SELECT username FROM admin_users WHERE operator = o.uid) "admin_username"
  FROM operators o
  WHERE o.uid = $[uid]
`;

const GET_POLICIES = `
  SELECT
    uid "value"
  , name "label"
  FROM policies
`;

type Policies = {
  value: string;
  label: string;
}[];

type Entity = CurrentValue & {
  admin_username: string;
};

type CurrentValue = {
  policies: string[];
  permissions: string[];
};

type Props = {
  uid: string;
  onClose: () => void;
};

export function EditPermissions(props: Props) {
  const [value, setValue] = useState<CurrentValue>({
    policies: [],
    permissions: [],
  });

  const availablePolicies = useQuery<Policies>(GET_POLICIES);
  const availablePermissions = useFetch<string[]>('/permissions');

  const operator = useQueryEntity<Entity, { uid: string }>(GET_OPERATOR, {
    variables: { uid: props.uid },
    skip: props.uid === '',
  });

  const { run: updatePermissions, loading } = useMutation<void, CurrentValue>(
    'operators/{uid}/permissions',
    {
      method: 'put',
    }
  );

  const dataSource = useMemo(() => {
    if (operator.data?.admin_username) {
      return availablePermissions.data;
    }
    // remove permissions from admin if operator is not used in backoffice
    return availablePermissions.data?.filter((it) => !it.startsWith('admin:'));
  }, [operator.data, availablePermissions.data]);

  useEffect(
    () =>
      setValue({
        policies: operator.data?.policies || [],
        permissions: operator.data?.permissions || [],
      }),
    [operator.data]
  );

  const onClick = useCallback(() => {
    updatePermissions(value, props)
      .then(() => message.success('Permissions updated with success!'))
      .catch((err) => {
        console.error(err);
        message.error('Ops, Something went wrong!');
      });
  }, [value]);

  return (
    <Drawer
      closable={false}
      title="Edit Permissions"
      open={Boolean(props.uid)}
      onClose={props.onClose}
      width={800}
      extra={
        <Space>
          <Button disabled={loading} size="small" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            size="small"
            type="primary"
            onClick={onClick}
          >
            Save
          </Button>
        </Space>
      }
    >
      {operator.data?.admin_username && (
        <Statistic
          title="Backoffice Username"
          value={operator.data?.admin_username}
          valueStyle={{ color: '#b22330' }}
        />
      )}

      <Divider>Policies / Shared Permissions</Divider>

      <Select
        allowClear
        mode="multiple"
        style={{ width: '100%' }}
        disabled={operator.loading}
        options={
          availablePolicies.data?.sort((a, b) => a.label.localeCompare(b.label)) || []
        }
        value={value.policies}
        onChange={(policies) =>
          setValue(({ permissions }) => ({ policies, permissions }))
        }
      />

      <Divider>Permissions / Individual Permissions</Divider>

      <Transfer
        showSearch
        titles={['Available', 'Allowed']}
        render={(item) => item.name}
        listStyle={{ width: 350, height: 460 }}
        dataSource={dataSource?.map((code) => ({ key: code, name: code }))}
        disabled={operator.loading}
        targetKeys={value.permissions}
        onChange={(permissions) =>
          setValue(({ policies }) => ({ policies, permissions }))
        }
      />
    </Drawer>
  );
}
