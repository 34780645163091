import React from 'react';
import { Checkbox, Select, Switch } from 'antd';
import { FormItem } from '~/components/BaseForm';
import { PreferencesForm } from '../abstract/PreferencesForm';

type Props = {
  organization: string;
};

export const ReportPreferencesTab: React.FC<Props> = ({ organization }) => (
  <PreferencesForm segment="report" organization={organization}>
    <FormItem
      name="version"
      label="Template Version"
      rules={[{ required: true }]}
      initialValue="report-v2"
    >
      <Select defaultActiveFirstOption>
        <Select.Option value="report-v2">Report V2</Select.Option>
      </Select>
    </FormItem>

    <FormItem label="Hidden Pages" name="hiddenPages">
      <Checkbox.Group className="qt-checkbox-group-vertical">
        <Checkbox value="cover">Cover</Checkbox>
        <Checkbox value="diagnosis">Diagnosis</Checkbox>
        <Checkbox value="hours">Summary Table</Checkbox>
        <Checkbox value="pacemaker">Pacemaker Table</Checkbox>
        <Checkbox value="hrv-time">Heart Rate Variability - Time Domain</Checkbox>
        <Checkbox value="events">Exam Events</Checkbox>
        <Checkbox value="strips">ECG Strips</Checkbox>
      </Checkbox.Group>
    </FormItem>

    <FormItem label="Hide Footer Logo" name="hideFooterLogo" valuePropName="checked">
      <Switch />
    </FormItem>

    <FormItem
      label="Use Time Artifacts"
      name="useTimeArtifacts"
      valuePropName="checked"
      initialValue={false}
    >
      <Switch />
    </FormItem>

    <FormItem label="Show Unknown Beats" name="showUnknown" initialValue="hide">
      <Select defaultActiveFirstOption>
        <Select.Option value="hide">Hide</Select.Option>
        <Select.Option value="unit">Unit count</Select.Option>
        <Select.Option value="time">Time period</Select.Option>
      </Select>
    </FormItem>

    <FormItem
      label="Use Diagnosis Template"
      name="useDiagnosisTemplate"
      valuePropName="checked"
      initialValue={false}
    >
      <Switch />
    </FormItem>
  </PreferencesForm>
);
