import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { useQuery } from '~/dataSource/useQuery';
import { LocalDate } from '~/components/LocalDate';
import { ExamStatus } from '~/components/ExamStatus';
import { Organization } from '~/entities/Organization';
import { durationHuman } from '~/helpers/duration';

const GET_EXAMS = `
  SELECT
    e.uid
  , e.patient->>'name' as "patientName"
  , e.options->>'duration' as duration
  , e.status
  , e.organization as organization
  , e.metadata->>'firmwareVersion' "firmwareVersion"
  , e.created_at
  , etv.receiving_packets
  , etv.packets_received
  FROM exams_view e
  JOIN exams_timers_view etv ON etv.exam = e.uid
  WHERE e.device->>'uid' = $[device]
  ORDER BY etv.receiving_packets DESC NULLS LAST
  LIMIT 10
`;

interface GetExamsVars {
  device: string;
}

interface Entity {
  uid: string;
  patientName: string;
  firmwareVersion: string;
  duration: number;
  status: string;
  organization: {
    uid: string;
    name: string;
  };
  created_at: Date;
  receiving_packets: Date;
  packets_received: Date;
}

type Props = { device: string };

export const DeviceLastExamsTable: React.FC<Props> = ({ device }) => {
  const { data, loading } = useQuery<Entity[], GetExamsVars>(GET_EXAMS, {
    variables: { device },
    skip: !device,
  });

  return (
    <Table
      caption="List of the latest 10 exams sorted by start time."
      loading={loading}
      dataSource={data}
      pagination={false}
      rowKey="uid"
      columns={[
        {
          width: 100,
          title: 'UID',
          dataIndex: 'uid',
          render: (uid: string) => (
            <Link to={`/exams/${uid}`}>
              <span style={{ fontFamily: 'monospace' }}>{uid.split('-')[0]}</span>
            </Link>
          ),
        },
        {
          title: 'Patient Name',
          dataIndex: 'patientName',
        },
        {
          title: 'Firmware Version',
          dataIndex: 'firmwareVersion',
        },
        {
          title: 'Duration',
          dataIndex: 'duration',
          render: (duration: number) => durationHuman(duration),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (status: string) => <ExamStatus status={status} />,
        },
        {
          title: 'Organization',
          dataIndex: 'organization',
          render: (organization: Organization) => (
            <Link to={`/organizations/${organization?.uid}`}>{organization?.name}</Link>
          ),
        },
        {
          title: 'Started',
          dataIndex: 'receiving_packets',
          render: (it) => <LocalDate datetime={it} />,
        },
        {
          title: 'Processing',
          dataIndex: 'packets_received',
          render: (it) => <LocalDate datetime={it} />,
        },
      ]}
    />
  );
};
