import { Button, Divider, Table } from 'antd';
import { Link } from 'react-router-dom';
import { PageHeader } from '~/components/PageHeader';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';

const GET_POLICIES = `
  SELECT
    uid
  , name
  , description
  , array_length(permissions, 1) "permissionsCount"
  FROM policies
  $[[WHERE]]
  ORDER BY name ASC
`;

interface Entity {
  uid: string;
  name: string;
  description: string;
  permissionsCount: number;
}

export function ListPolicies() {
  const entities = useQueryPaginated<Entity>(GET_POLICIES, {
    pagination: {
      perPage: 20,
    },
  });

  return (
    <>
      <PageHeader
        title="Policies List"
        extra={[
          <Link to="new" key="add-new">
            <Button type="primary">Create</Button>
          </Link>,
        ]}
      />

      <Divider />

      <Table<Entity>
        rowKey="uid"
        loading={entities.loading}
        dataSource={entities.rows}
        footer={() => (
          <>
            Total <strong>{entities.totalCount}</strong>
          </>
        )}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Description',
            dataIndex: 'description',
          },
          {
            title: 'Permissions',
            dataIndex: 'permissionsCount',
          },
          {
            title: 'Actions',
            dataIndex: 'uid',
            render: (uid) => <Link to={uid}>Edit</Link>,
          },
        ]}
      />
    </>
  );
}
