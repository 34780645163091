export function generateHostLink(serialNumbers: string[]) {
  if (!serialNumbers.length) {
    return '#';
  }

  const withPrefix = serialNumbers.map((it) => (/^\d+/.test(it) ? `sn${it}` : it));

  if (withPrefix.length === 1) {
    return `https://app.datadoghq.com/logs?query=host:${withPrefix[0]}&cols=host%2Cservice&index=&viz=stream`;
  }

  const groupQuery = encodeURIComponent(`(${withPrefix.join(' OR ')})`);
  return `https://app.datadoghq.com/logs?query=host:${groupQuery}&cols=host%2Cservice&index=&viz=stream`;
}

export function generateExamLink(exam: string) {
  const query = encodeURIComponent(`(@exam:${exam} OR exam:${exam})`);
  return `https://app.datadoghq.com/logs?query=${query}&cols=host%2Cservice&index=&viz=stream`;
}
