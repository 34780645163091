import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Protected } from '~/components/Protected';
import { ListPhysicians } from './routes/ListPhysicians';
import { EditPhysician } from './routes/EditPhysician';
import { Workload } from './routes/Workload';

export const PhysiciansRoutes: FC = () => (
  <Protected scope="admin:people:list">
    <Routes>
      <Route path="/workload" element={<Workload />} />
      <Route path="/" element={<ListPhysicians />} />
      <Route path=":uid" element={<EditPhysician />} />
    </Routes>
  </Protected>
);
