import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Table, Input } from 'antd';
import { Exam } from '~/entities/Exam';
import { durationHuman } from '~/helpers/duration';
import { LocalDate } from '~/components/LocalDate';
import { PageHeader } from '~/components/PageHeader';
import { ExamStatus } from '~/components/ExamStatus';
import { ServiceType } from '~/components/ServiceType';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';
import { ExamsStatistics } from '../components/ExamsStatistics';

const GET_EXAMS = `
  SELECT
    e.uid
  , e.status
  , e.options
  , e.created_at
  , e.device
  , e.patient
  , e.service_type
  , e.organization
  FROM exams_view e
  $[[WHERE]]
  ORDER BY e.created_at DESC
`;

interface Entity {
  uid: string;
  status: string;
  service_type?: string;
  options: { duration: number };
  created_at: Date;
  patient: { name: string; document: string; document_type: string };
  device?: { uid: string; serial_number: string };
  organization: { name: string };
}

const RESULTS_PER_PAGE = 20;

export const ListExams: FC = () => {
  const { rows, totalCount, loading, pagination, paginate, refetch } =
    useQueryPaginated<Entity>(GET_EXAMS, {
      pagination: {
        perPage: RESULTS_PER_PAGE,
      },
    });

  function onSearch(value: string) {
    const _value = value.trim().split(' ').join('%');
    const _where = value.startsWith('>')
      ? `WHERE ${value.replace('>', '')}`
      : value
      ? `WHERE patient->>'name' ILIKE '%${_value}%' OR patient->>'document' ILIKE '%${_value}%'`
      : '';

    paginate({ page: 1 });
    refetch({ _where });
  }

  return (
    <>
      <PageHeader title="Statistics">
        <ExamsStatistics />
      </PageHeader>

      <Divider />

      <PageHeader title="Exam List" />

      <div style={{ padding: '0 12px' }}>
        <Input.Search
          enterButton
          allowClear
          placeholder="Filter by Patient Name or Patient Document"
          onSearch={onSearch}
        />
        <br />
        <br />
        <Table<Entity>
          rowKey="uid"
          loading={loading}
          dataSource={rows}
          onChange={(pagination) => {
            paginate({ page: pagination.current, perPage: pagination.pageSize });
          }}
          pagination={{
            showSizeChanger: true,
            total: totalCount,
            current: pagination.page,
            pageSize: pagination.perPage,
          }}
          columns={[
            {
              width: 70,
              title: 'UID',
              dataIndex: 'uid',
              render: (uid: string) => <code>{uid.split('-')[0]}</code>,
            },
            { title: 'Patient Name', dataIndex: ['patient', 'name'] },
            { title: 'Patient Document', dataIndex: ['patient', 'document'] },
            { title: 'Organization', dataIndex: ['organization', 'name'] },
            { title: 'Device', dataIndex: ['device', 'serial_number'] },
            {
              title: 'Status',
              dataIndex: 'status',
              render: (status: string) => <ExamStatus status={status} />,
            },
            {
              title: 'Service Type',
              dataIndex: 'service_type',
              render: (serviceType: string) => <ServiceType children={serviceType} />,
            },
            {
              title: 'Duration',
              dataIndex: 'options',
              render: (options: Exam['options']) => durationHuman(options.duration),
            },
            {
              title: 'Created At',
              dataIndex: 'created_at',
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              key: 'actions',
              title: 'Actions',
              dataIndex: 'uid',
              width: 120,
              render: (uid: string) => (
                <>
                  <Link to={`/exams/${uid}`}>Details</Link>
                  <Divider type="vertical" />
                  <a
                    href={`${process.env.REACT_APP_PLATFORM_URL}/exam/${uid}`}
                    target="_blank"
                    rel="noopener noparent"
                  >
                    Open
                  </a>
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};
