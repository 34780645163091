import { Tag } from 'antd';
import React from 'react';

export const ServiceTypeOptions = new Map()
  // .set('taas', { label: 'TaaS', color: 'green' })
  // .set('daas', { label: 'DaaS', color: 'orange' })
  // .set('essential', { label: 'Essential', color: 'cyan' });
  .set('standard', { label: 'Standard', color: 'blue' })
  .set('premium', { label: 'Premium', color: 'purple' });

export const ServiceType: React.FC<{ children?: string }> = ({ children }) => {
  if (!children) {
    return <Tag color="default">-</Tag>;
  }

  const serviceType = ServiceTypeOptions.get(children);

  if (!serviceType) {
    return <Tag>{children}</Tag>;
  }

  return <Tag color={serviceType.color}>{serviceType.label}</Tag>;
};
