import { createRoot } from 'react-dom/client';
import { Login } from './screens/Login/Login';
import { App } from './App';
import { Theme } from './Theme';
import './index.css';
import { Amplify } from '@aws-amplify/core';
import { recover } from '~/models/user';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const $container = createRoot(document.getElementById('root')!);

const redirectURL = `${window.location.protocol}//${window.location.host}/`;

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
    oauth: {
      redirectSignIn: redirectURL,
      redirectSignOut: redirectURL,
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      responseType: 'code',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    },
  },
});

const urlParams = new URLSearchParams(window.location.search);
const error = urlParams.get('error');
const errorDescription = urlParams.get('error_description');
if (error) {
  console.error('Erro no login federado:', error);
  window.location.href = `/?errorMessage=${errorDescription ?? error}`;
}

const MainScreen = () =>
  $container.render(
    <Theme>
      <App />
    </Theme>
  );

document.addEventListener('DOMContentLoaded', async () => {
  console.log('application started! env:', process.env.NODE_ENV);

  try {
    await recover();
    MainScreen();
  } catch (err) {
    console.error('recover', err);
    return $container.render(
      <Theme>
        <Login />
      </Theme>
    );
  }
});
