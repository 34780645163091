import { FC } from 'react';
import { Divider, Input, Select, Spin, Typography } from 'antd';
import { useLocationSearch } from '~/hooks/useLocationSearchHook';
import { BaseForm, BaseFormProps, FormItem } from '~/components/BaseForm';

export const DeviceForm: FC<BaseFormProps> = (props) => {
  const { data, loading, search } = useLocationSearch();
  return (
    <BaseForm {...props}>
      <FormItem
        label="MAC Address"
        shouldUpdate={(prevValues, curValues) =>
          prevValues.mac_address !== curValues.mac_address
        }
      >
        {({ getFieldValue }) => {
          const text = getFieldValue('mac_address')
            ?.replace(/.{1,2}/g, (v: string) => v + ':')
            .toUpperCase()
            .slice(0, -1);

          return (
            <>
              <Typography.Text copyable={{ text }}>{text}</Typography.Text>
              <Divider type="vertical" />
              <Typography.Text copyable={{ text: getFieldValue('mac_address') }}>
                {getFieldValue('mac_address')}
              </Typography.Text>
            </>
          );
        }}
      </FormItem>

      <FormItem
        label="Location"
        name="location"
        rules={[{ required: true, message: 'please input device location' }]}
      >
        <Select
          showSearch
          labelInValue
          showArrow={false}
          filterOption={false}
          onSearch={(value) => search(value, !value)}
          notFoundContent={loading ? <Spin size="small" /> : null}
          options={data?.map(({ uid: value, name: label }) => ({ value, label }))}
        />
      </FormItem>

      <FormItem
        label="Serial number"
        name="serial_number"
        rules={[{ required: true, message: 'please input device serial number' }]}
      >
        <Input readOnly />
      </FormItem>

      <FormItem
        label="Firmware version"
        name="firmware_version"
        rules={[{ required: true, message: 'please input device firmware version' }]}
      >
        <Input readOnly />
      </FormItem>
    </BaseForm>
  );
};
