import { Button, Descriptions, List, Space, Typography } from 'antd';
import { useState } from 'react';
import { getCurrent } from '~/models/user';
import { useMutation } from '~/dataSource/useMutation';

type AccountStatus = {
  UserStatus: string;
};

type Creds = {
  username: string;
  temporaryPassword: string;
};

type Props = {
  person?: {
    uid: string;
    username: string;
  };
};

export const LoginCredentials: React.FC<Props> = ({ person }) => {
  const [creds, setCredentials] = useState<null | Creds>();
  const [status, setAccountStatus] = useState<null | AccountStatus>();

  const resetPassword = useMutation<Creds>(`physicians/[uid]/password`, {
    method: 'put',
  });

  const checkStatus = useMutation<AccountStatus, Partial<Props['person']>>(
    `physicians/${person?.uid}/status?username=[username]`,
    {
      method: 'get',
    }
  );

  function handleResetPassword() {
    setCredentials(null);
    setAccountStatus(null);

    // FIXME
    resetPassword.run(undefined, person).then((response) => {
      setCredentials(response);
    });
  }

  function handleCheckUserStatus() {
    setCredentials(null);
    setAccountStatus(null);

    checkStatus.run({}, { username: person?.username }).then((response) => {
      setAccountStatus(response);
    });
  }

  return (
    <>
      <List>
        {person?.username && (
          <List.Item>
            <strong>Username:</strong> {person?.username}
          </List.Item>
        )}

        {status && (
          <List.Item>
            <strong>Status:</strong> {status.UserStatus}
          </List.Item>
        )}
        <List.Item>
          <Space size="large">
            {getCurrent().can('admin:people:password:reset') && (
              <Button
                size="small"
                onClick={handleResetPassword}
                loading={resetPassword.loading}
              >
                Reset Account Password
              </Button>
            )}

            {getCurrent().can('admin:people:status') && (
              <Button
                size="small"
                onClick={handleCheckUserStatus}
                loading={checkStatus.loading}
              >
                Check Account Status
              </Button>
            )}
          </Space>
        </List.Item>

        {creds && (
          <List.Item>
            <Descriptions title="New Credentials">
              <Descriptions.Item label="Username">
                <Typography.Text copyable>{creds.username}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Temporary Password">
                <Typography.Text copyable>{creds.temporaryPassword}</Typography.Text>
              </Descriptions.Item>
            </Descriptions>
          </List.Item>
        )}
      </List>
    </>
  );
};
