import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Divider, Input, Tag } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';
import { LocalDate } from '~/components/LocalDate';
import { PageHeader } from '~/components/PageHeader';
import { ServiceType } from '~/components/ServiceType';
import { OrganizationsStatistics } from '../components/OrganizationsStatistics';

const GET_ORGANIZATIONS = `
  SELECT
    o.uid
  , o.name
  , o.legal_name
  , o.account_name
  , o.status
  , o.document
  , o.created_at
  , o.service_type
  , p.name parent_name
  FROM organizations_view o
  LEFT JOIN organizations p ON p.uid = o.parent_uid
  $[[WHERE]]
  ORDER BY o.name ASC
`;

interface Entity {
  uid: string;
  name: string;
  legal_name: string;
  account_name: string;
  status: string;
  document: string;
  service_type: string | null;
  parent_name: string;
  created_at: string;
}

export const ListOrganizations: React.FC = () => {
  const { rows, totalCount, loading, paginate, refetch, pagination } =
    useQueryPaginated<Entity>(GET_ORGANIZATIONS, {
      pagination: { perPage: 20 },
    });

  function onSearch(value: string) {
    const _value = value.trim().split(' ').join('%');
    const _where = value
      ? `WHERE o.name ILIKE '%${_value}%' OR o.legal_name ILIKE '%${_value}%' OR o.account_name ILIKE '%${_value}%' OR o.document ILIKE '%${_value}%' OR p.name ILIKE '%${_value}%'`
      : '';
    paginate({ page: 1 });
    refetch({ _where });
  }

  function onChange(pagination: TablePaginationConfig) {
    paginate({ page: pagination.current, perPage: pagination.pageSize });
  }

  return (
    <>
      <PageHeader
        title="Organization List"
        extra={[
          <Link key="1" to="/organizations/new">
            <Button type="primary">Create</Button>
          </Link>,
        ]}
      >
        <OrganizationsStatistics />
      </PageHeader>

      <Divider />

      <div style={{ padding: '0 12px' }}>
        <Input.Search
          enterButton
          allowClear
          placeholder="Filter by Name"
          onSearch={onSearch}
        />

        <br />
        <br />

        <Table<Entity>
          rowKey="uid"
          loading={loading}
          dataSource={rows}
          onChange={onChange}
          footer={() => (
            <>
              Total: <strong>{totalCount}</strong>
            </>
          )}
          pagination={{
            showSizeChanger: true,
            total: totalCount,
            current: pagination.page,
            pageSize: pagination.perPage,
          }}
          columns={[
            { title: 'Name', dataIndex: 'name' },
            { title: 'Legal Name', dataIndex: 'legal_name' },
            { title: 'Account Name', dataIndex: 'account_name' },
            { title: 'Document', dataIndex: 'document' },
            {
              title: 'Parent',
              dataIndex: 'parent_name',
            },
            {
              title: 'Status',
              dataIndex: 'status',
              render: (value: string) => (
                <Tag
                  color={value === 'active' ? 'green' : 'red'}
                  style={{ textTransform: 'capitalize' }}
                >
                  {value}
                </Tag>
              ),
            },
            {
              title: 'Service Type',
              dataIndex: 'service_type',
              render: (value: string) => <ServiceType children={value} />,
            },
            {
              title: 'Created At',
              dataIndex: 'created_at',
              render: (it) => <LocalDate datetime={it} />,
            },
            {
              key: 'actions',
              title: 'Actions',
              dataIndex: 'uid',
              width: 120,
              render: (uid) => {
                return (
                  <>
                    <Link to={`/organizations/${uid}`}>Details</Link>
                    <Divider type="vertical" />
                    <Link to={`/organizations/${uid}/edit`}>Edit</Link>
                  </>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};
