import { Card, Collapse, Descriptions, Empty, Spin, Table, Tag } from 'antd';
import { LocalDate } from '~/components/LocalDate';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { useQueryPaginated } from '~/dataSource/useQueryPaginated';

function getStatusColor(status: string) {
  switch (status) {
    case 'completed':
      return 'green';
    case 'error':
      return 'red';
  }
}

const GET_NEOMED_EXAM = `
  SELECT *
  FROM neomed.exams
  WHERE quoretech_uid = $[uid]
`;

const GET_NEOMED_TASKS = `
  SELECT nt.*
  FROM neomed.tasks nt
  JOIN neomed.exams ne ON ne.uid = nt.neomed_exam_uid
  WHERE ne.quoretech_uid = $[uid]
  ORDER BY nt.created_at DESC
`;

type ExamEntity = {
  integration_key: `${string}_1_0`;
  created_at: string;
  synced_at: string;
};

type Entity = {
  uid: string;
  status: string;
  created_at: string;
  completed_at: string;
  error: any;
  input: Record<string, unknown>;
};

type Props = {
  uid: string;
};

export function NeomedDetails({ uid }: Props) {
  const exam = useQueryEntity<ExamEntity, Props>(GET_NEOMED_EXAM, {
    variables: { uid },
  });
  const neomed = useQueryPaginated<Entity>(GET_NEOMED_TASKS, { variables: { uid } });

  if (exam.loading) {
    return <Spin />;
  }

  if (!exam.data && !exam.loading) {
    return <Empty />;
  }

  return (
    <>
      <Card title="Integration">
        <Descriptions>
          <Descriptions.Item label="Key">
            {exam.data?.integration_key}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            <LocalDate datetime={exam.data?.created_at} />
          </Descriptions.Item>
          <Descriptions.Item label="Last Synced At">
            <LocalDate datetime={exam.data?.synced_at} />
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <br />
      <Table<Entity>
        rowKey={'uid'}
        expandable={{
          expandedRowRender: (it) => {
            return (
              <Collapse>
                <Collapse.Panel key={'error'} header="Error">
                  <pre>
                    <code>{JSON.stringify(it.error, null, 2)}</code>
                  </pre>
                </Collapse.Panel>
                <Collapse.Panel key={'input'} header="Input">
                  <pre>
                    <code>{JSON.stringify(it.input, null, 2)}</code>
                  </pre>
                </Collapse.Panel>
              </Collapse>
            );
          },
        }}
        columns={[
          {
            title: 'UID',
            dataIndex: 'uid',
          },
          {
            title: 'Action',
            render(it) {
              if (it.input.result) {
                return 'report-signed';
              }

              return it.input.protocol.description_action_protocol;
            },
          },

          {
            title: 'Description',
            render(it) {
              return (
                it.input.protocol?.identification_system_description
                  ?.system_description ||
                it.input.report?.identification?.redo_template_title ||
                '-'
              );
            },
          },
          {
            title: 'Status',
            render(it) {
              return it.input.protocol?.identification?.status || '-';
            },
          },
          {
            title: 'Task Status',
            dataIndex: 'status',
            render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            render: (it) => <LocalDate datetime={it} />,
          },
          {
            title: 'Completed At',
            dataIndex: 'completed_at',
            render: (it) => <LocalDate datetime={it} />,
          },
        ]}
        dataSource={neomed.rows}
      />
    </>
  );
}
