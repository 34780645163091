import { Route, Routes } from 'react-router-dom';
import { Protected } from '~/components/Protected';
import { NewPolicy } from './routes/NewPolicy';
import { EditPolicy } from './routes/EditPolicy';
import { ListPolicies } from './routes/ListPolicies';

export function PoliciesRoutes() {
  return (
    <Protected scope="admin:policies:list">
      <Routes>
        <Route path="/" element={<ListPolicies />} />
        <Route
          path="/new"
          element={
            <Protected scope="admin:policies:create">
              <NewPolicy />
            </Protected>
          }
        />
        <Route
          path="/:uid"
          element={
            <Protected scope="admin:policies:update">
              <EditPolicy />
            </Protected>
          }
        />
      </Routes>
    </Protected>
  );
}
