import React from 'react';
import { useQueryEntity } from '~/dataSource/useQueryEntity';

const GET_EXAM = `
  WITH operators_view AS (
    SELECT
        op.uid
      , op.organization
      , p.name
      , p.document
      , p.document_type
      FROM operators op
      JOIN physicians p on p.uid = op.physician
  )
  SELECT
    row_to_json(e.*) as exam
  , row_to_json(p.*) as patient
  , row_to_json(d.*) as device
  , row_to_json(rq.*) as requestor
  , row_to_json(rp.*) as reporter
  , row_to_json(t.*) as technician
  , row_to_json(l.*) as location
  , row_to_json(o.*) as organization
  , row_to_json(i.*) as invoice
  , json_build_object(
      'task_id', zt.task_id
    , 'ticket_id', zt.ticket_id
    , 'project_id', zp.project_id
    , 'tasklist_id', zp.tasklist_id
  ) as task
  , array(
      SELECT row_to_json(es.*)
      FROM exams_status es
      WHERE es.exam = e.uid
      ORDER BY created_at ASC
    ) as statuses
  , array(
      SELECT jsonb_build_object(
        'deleted_at', dr.deleted_at,
        'status', dr."data"->>'status',
        'created_at', dr."data"->>'created_at'
      )
      FROM deleted_records dr
      WHERE dr.original_id = e.uid
      AND dr.original_table = 'exams_status'
    ) AS deleted_statuses
  FROM exams e
  JOIN patients p ON p.uid = e.patient
  JOIN operators_view AS rq ON rq.uid = e.requestor
  LEFT JOIN organizations_invoices_exams i ON i.exam = e.uid
  LEFT JOIN devices d ON d.uid = e.device
  LEFT JOIN locations l ON l.uid = e.location
  LEFT JOIN organizations o ON o.uid = COALESCE(l.organization, rq.organization)
  LEFT JOIN operators_view AS rp ON rp.uid = e.reporter
  LEFT JOIN operators_view AS t ON t.uid = e.technician
  LEFT JOIN zoho_tasks zt ON zt.exam = e.uid
  LEFT JOIN zoho_projects zp on zp.organization = zt.organization
  WHERE e.uid = $[uid]
`;

interface OperatorEntity {
  uid: string;
  name: string;
  document: string;
  document_type: string;
}

interface ExamEntity {
  exam: {
    uid: string;
    status: string;
    mode: string;
    diagnosis?: string;
    reasons: string[];
    symptoms: string[];
    metadata: {
      timeZone: string;
      appVersion: string;
      firmwareVersion: string;
      purged_at?: string;
      qualityIndex?: string;
    };
    prescriber: null | {
      name: string;
      document: string;
    };
    settings: {
      pauseDuration?: number;
      supraThreshold?: number;
      heartRateCriteria?: string;
      quoreleadVersion?: string;
      pause_duration?: number;
      supra_threshold?: number;
      heart_rate_criteria?: string;
      quorelead_version?: string;
    };
    demographics: {
      height: number;
      weight: number;
      smoker: boolean;
      pacemaker: boolean;
    };
    options: {
      leads: number;
      duration: number;
      frequency: number;
      selfInstall: boolean;
    };
  };
  requestor: OperatorEntity;
  reporter?: OperatorEntity;
  technician?: OperatorEntity;
  patient: {
    uid: string;
    name: string;
    sex: string;
    birthdate: string;
    email: string;
    document: string;
    document_type: string;
    created_at: string;
  };
  invoice?: {
    exam: string;
    invoice: string;
    service_type: string;
  };
  device?: {
    uid: string;
    mac_address: string;
    serial_number: string;
    firmware_version: string;
  };
  location?: {
    uid: string;
    name: string;
  };
  organization: {
    uid: string;
    name: string;
  };
  task?: {
    task_id: string;
    project_id: string;
    tasklist_id: string;
    ticket_id: string;
  };
  statuses: {
    status: string;
    created_at: string;
  }[];
  deleted_statuses: {
    status: string;
    created_at: string;
    deleted_at: string;
  }[];
}

interface Props {
  uid: string;
}

interface ContextProps {
  data?: ExamEntity | null;
  loading: boolean;
  refetch: () => void;
}

const ViewExamContext = React.createContext<ContextProps>({
  data: null,
  loading: false,
  refetch: () => void 0,
});

export function useViewExamContext() {
  return React.useContext(ViewExamContext);
}

export function ViewExamContextProvider({
  uid,
  children,
}: React.PropsWithChildren<Props>) {
  const { data, loading, refetch } = useQueryEntity<ExamEntity, Props>(GET_EXAM, {
    variables: { uid },
    skip: !uid,
  });

  const value = React.useMemo(
    () => ({
      data,
      loading,
      refetch,
    }),
    [data, loading]
  );

  return <ViewExamContext.Provider value={value}>{children}</ViewExamContext.Provider>;
}
