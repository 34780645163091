import { useEffect } from 'react';
import { Card, Divider, Form, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '~/components/PageHeader';
import { useFetch } from '~/dataSource/useFetch';
import { useQueryEntity } from '~/dataSource/useQueryEntity';
import { PolicyEntity } from '../entities/Policy';
import { PolicyForm } from '../components/PolicyForm';
import { useMutation } from '~/dataSource/useMutation';

const GET_POLICY = `
  SELECT
    uid
  , name
  , description
  , permissions
  FROM policies
  WHERE uid = $[uid]
`;

type Params = { uid: string };

export function EditPolicy() {
  const navigate = useNavigate();
  const { uid } = useParams<Params>();

  if (!uid) return null;

  const availablePermissions = useFetch<string[]>('/permissions');

  const updatePolicy = useMutation('/policies/[uid]', {
    method: 'put',
  });

  const policy = useQueryEntity<PolicyEntity, Params>(GET_POLICY, {
    variables: { uid },
    skip: !uid,
  });

  const [form] = Form.useForm<PolicyEntity>();

  useEffect(() => {
    if (policy.data) form.setFieldsValue(policy.data);
  }, [availablePermissions.data, policy.data]);

  function onFinish(input: any) {
    updatePolicy
      .run(input, { uid })
      .then(() => message.success('Policy updated with success'))
      .catch(() => message.error('Ops, something went wrong!'));
  }

  return (
    <>
      <PageHeader
        title={`Policy: ${policy.loading ? '...' : policy.data?.name || ''}`}
        onBack={() => navigate('/policies')}
      />

      <Divider />

      <Card>
        <PolicyForm
          form={form}
          onFinish={onFinish}
          availablePermissions={availablePermissions.data || []}
        />
      </Card>
    </>
  );
}
